<template>
  <div
    class="max-h-500px 2xl:max-h-[calc(100vh-595px)] overflow-y-auto hidden-scroll"
    @scroll="checkScroll"
  >
    <a-table
      :has-checkbox="false"
      :index-column="false"
      :data-source="listNonCheckout"
      ref="table"
      :pagination="false"
      :columns="columns"
      :loading="isLoading"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'stt' && record">
          {{ index + 1 }}
        </template>
        <template v-if="column.key === 'img' && record">
          <a-tooltip
            placement="bottom"
            :title="t('common.view-details', { name: t('common.event') })"
            overlay-class-name="tooltip-view-more"
          >
            <div
              class="flex justify-center w-full h-full img-custom hover:cursor-pointer"
              @click="onView(record)"
            >
              <img
                class="w-[42px] h-[42px] object-cover"
                :src="
                  record.imageCrop
                    ? record.imageCrop
                    : require('@/static/img/no-img.png')
                "
                alt=""
              />
            </div>
          </a-tooltip>
        </template>
        <template v-if="column.key === 'cameraName' && record">
          <div class="text-left w-full break-all leading-[1]">
            {{ record.cameraName }}
          </div>
        </template>
        <template v-if="column.key === 'time_check_in' && record">
          <div>
            {{ dayjs(record.createdAt).format('DD/MM/YYYY - HH:mm:ss') }}
          </div>
        </template>
      </template>
    </a-table>
    <div class="flex justify-center pb-2">
      <a-button
        v-if="isShowButton"
        type="primary"
        ghost
        class="!text-ems-main2 !border-ems-main2 text-base font-semibold"
        @click="handleViewMore()"
      >
        {{ t('common.view-more') }}
      </a-button>
    </div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import {
  FORM_MODE,
  OBJECT_TYPE,
  OBJECT_TYPE_TEXT,
} from '@/util/common-constant';
import { getText } from '@/util/common-utils';

defineProps({});

const { t } = useI18n();
const { state, dispatch } = useStore();
const isShowButton = ref(false);
const route = useRoute();
const router = useRouter();

const columns = ref([
  {
    title: t('report_statistical.image'),
    key: 'img',
    width: 90,
    resizable: true,
    dataIndex: 'imageCrop',
  },
  {
    title: t('group-management.select-type-placeholder'),
    key: 'type',
    resizable: true,
    dataIndex: 'type',
    width: 150,
    customRender: ({ record }) => {
      return t(getText(record.type, OBJECT_TYPE_TEXT));
    },
  },
  {
    title: t('camera.name'),
    key: 'cameraName',
    resizable: true,
    dataIndex: 'cameraName',
    width: 250,
  },
  {
    title: t('user_group.permission_time'),
    key: 'time_check_in',
    resizable: true,
    dataIndex: 'time_check_in',
    width: 200,
  },
]);

const handleViewMore = () => {
  dispatch(
    'reportStatistical/fetchNonCheckouts',
    listNonCheckout.value.length + 10
  );
  isShowButton.value = false;
};

const checkScroll = (e) => {
  if (
    e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
    listNonCheckout.value.length !== listDraftNonCheckout.value.length
  ) {
    isShowButton.value = true;
  }
};

const onView = (row) => {
  router.push({
    path: '/trace-management/identification-event/detail',
    query: {
      id: row.id,
    },
  });
};

const listNonCheckout = computed(() => state.reportStatistical.nonCheckouts);
const listDraftNonCheckout = computed(
  () => state.reportStatistical.draftNonCheckouts
);
const isLoading = computed(() => state.reportStatistical.loading);
</script>
