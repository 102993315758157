<template>
  <div
    class="max-h-500px 2xl:max-h-[calc(100vh-595px)] overflow-y-auto hidden-scroll"
    @scroll="checkScroll"
  >
    <a-table
      :has-checkbox="false"
      :index-column="false"
      :data-source="listFrequency"
      ref="table"
      :pagination="false"
      :columns="columns"
      :loading="isLoading"
    >
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.key === 'stt' && record">
          {{ index + 1 }}
        </template>
        <template v-if="column.key === 'name'">
          <a-tooltip
            v-if="record.name"
            placement="bottom"
            :title="`${t('common.view')} ${t(
              'object_information.ident-history'
            )}`"
            overlay-class-name="tooltip-view-more"
          >
            <span
              class="text-ems-main4 hover:underline hover:cursor-pointer"
              @click="onView(record)"
            >
              {{ record.name ? record.name : record.id }}
            </span>
          </a-tooltip>
        </template>
        <template v-if="column.key === 'image' && record">
          <a-tooltip
            v-if="record.name"
            placement="bottom"
            :title="
              t('common.view-details', { name: t('common.latest-event') })
            "
            overlay-class-name="tooltip-view-more"
          >
            <div
              class="flex justify-center w-full h-full img-custom hover:cursor-pointer"
              @click="onViewEvent(record)"
            >
              <img
                class="w-[42px] h-[42px] object-cover"
                :src="
                  record.img ? record.img : require('@/static/img/no-img.png')
                "
                alt=""
              />
            </div>
          </a-tooltip>
        </template>
        <template v-if="column.key === 'location' && record">
          <div class="w-full text-left break-all leading-[1]">
            {{ record.location }}
          </div>
        </template>
      </template>
    </a-table>
    <div class="flex justify-center pb-2">
      <a-button
        v-if="isShowButton"
        type="primary"
        ghost
        class="!text-ems-main2 !border-ems-main2 text-base font-semibold"
        @click="handleViewMore()"
      >
        {{ t('common.view-more') }}
      </a-button>
    </div>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { FORM_MODE, OBJECT_TYPE, TAB_KEY } from '@/util/common-constant';
import { formatNumberReZero } from '@/util/common-utils';

defineProps({});
const { t } = useI18n();
const { state, dispatch } = useStore();
const isShowButton = ref(false);
const route = useRoute();
const router = useRouter();

const latestEvent = computed(() => state.identificationEvent.latestEvent);

const columns = ref([
  {
    title: t('report_statistical.image'),
    dataIndex: 'img',
    key: 'image',
    width: 90,
  },
  {
    title: 'ID',
    dataIndex: 'name',
    key: 'name',
    width: 200,
  },
  {
    title: t('report_statistical.frequency'),
    dataIndex: 'frequency',
    key: 'frequency',
    width: 150,
    customRender: ({ record }) => {
      return formatNumberReZero(record.frequency);
    },
  },
  {
    title: t('report_statistical.location'),
    dataIndex: 'location',
    key: 'location',
    width: 400,
  },
]);

const handleViewMore = () => {
  dispatch(
    'reportStatistical/fetchFrequencies',
    listFrequency.value.length + 10
  );
  isShowButton.value = false;
};

const onViewEvent = async (record) => {
  await dispatch('identificationEvent/getAll', {
    reset: true,
    size: 1,
    name: record.id,
    getLatestEvent: true,
    type: record.type,
  });
  if (latestEvent.value.id) {
    router.push({
      path: '/trace-management/identification-event/detail',
      query: {
        id: latestEvent.value.id,
      },
    });
  }
};

const onView = (record) => {
  router.push({
    name: '/io-management/object-management/edit',
    query: {
      id: record.id,
      type: OBJECT_TYPE[record.type],
      mode: FORM_MODE.VIEW,
      page: 1,
      ...route.query,
      tab: TAB_KEY.IDENTIFICATION_HISTORY,
    },
  });
};

const checkScroll = (e) => {
  if (
    e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
    listFrequency.value.length !== listDraftFrequency.value.length
  ) {
    isShowButton.value = true;
  }
};

const listFrequency = computed(() => state.reportStatistical.frequencies);
const listDraftFrequency = computed(
  () => state.reportStatistical.draftFrequencies
);
const isLoading = computed(() => state.reportStatistical.loading);
</script>
