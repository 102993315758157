<template>
  <div>
    <video
      ref="videoElement"
      class="video-js custom-video h-[400px] w-full"
      controls="true"
      autoplay="true"
    ></video>
  </div>
  <div class="controls z-[999] absolute bottom-0 w-full space-x-2">
    <div @click="togglePlay" class="cursor-pointer">
      <img
        v-if="isPlaying"
        alt="back"
        src="/assets/icons/pause.svg"
        class="cursor-pointer w-[36px]"
      />
      <img
        v-else
        alt="back"
        src="/assets/icons/playvideo.svg"
        class="cursor-pointer w-[36px]"
      />
    </div>
    <div @click="toggleMute" class="cursor-pointer">
      <img
        v-if="isMuted"
        alt="back"
        src="/assets/icons/mute_off.svg"
        class="cursor-pointer w-[36px]"
      />
      <img
        v-else
        alt="back"
        src="/assets//icons/mute_off.svg"
        class="cursor-pointer w-[36px]"
      />
    </div>
    <div @click="toggleFullscreen" class="cursor-pointer">
      <img
        alt="fullscreen"
        src="/assets/icons/fullscreen.svg"
        class="cursor-pointer w-[36px]"
      />
    </div>
    <input
      type="range"
      min="0"
      :max="props.duration"
      v-model="seekTime"
      @input="seekVideo"
      class="seek-bar"
    />
    <div
      class="min-h-[20px] min-w-[20px] border rounded-full flex justify-center items-center"
    >
      <div class="cursor-pointer">
        <img
          @click="handleCloseVideo"
          alt="back"
          src="/assets/icons/close.svg"
          class="cursor-pointer w-[26px]"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, defineProps } from 'vue';
import { defineEmits } from 'vue';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
// import 'dashjs';
import 'videojs-contrib-dash';
import VueTypes from 'vue-types';
const emit = defineEmits(['closeVideo']);
const props = defineProps({
  url: VueTypes.string.def(''),
  seekTime: VueTypes.number.def(0),
  duration: VueTypes.number.def(0),
});

const videoElement = ref(null);
const player = ref(null);
const isPlaying = ref(false);
const isMuted = ref(false);
const duration = ref(0);
const currentTime = ref(0);
const seekTime = ref(props.seekTime);

const handleCloseVideo = () => {
    if (player.value) {
    player.value.pause();
    player.value.src({ src: "", type: "" });

    const tech = player.value.tech({ IWillNotUseThisInPlugins: true });
    if (tech && tech.dash) {
      tech.dash.reset();
      tech.dash.destroy();
    }

    player.value.dispose();
    player.value = null;
  }
  if (videoElement.value) {
    videoElement.value.remove();
    videoElement.value = null;
  }
  emit("closeVideo");
};
const toggleFullscreen = () => {
  if (!document.fullscreenElement) {
    videoElement.value.requestFullscreen().catch((err) => {
      console.error(
        `Error attempting to enable fullscreen mode: ${err.message}`
      );
    });
  } else {
    document.exitFullscreen();
  }
};
onMounted(() => {
  console.log("seekTime",props.seekTime);
  console.log("duration",props.duration);
  
  if (videoElement.value && props.url) {
    player.value = videojs(videoElement.value, {
      techOrder: ['html5', 'dash'],
      controls: false,
      autoplay: true,
      sources: [{ src: props.url, type: 'application/dash+xml' }],
    });

    player.value.on('loadeddata', () => {
      duration.value = player.value.duration();
      player.value.currentTime(props.seekTime);
    });

    player.value.on('timeupdate', () => {
      currentTime.value = player.value.currentTime();
      seekTime.value = currentTime.value;
    });

    player.value.on('play', () => (isPlaying.value = true));
    player.value.on('pause', () => (isPlaying.value = false));
    player.value.on(
      'volumechange',
      () => (isMuted.value = player.value.muted())
    );
  }
});
watch(
  () => props.seekTime,
  (newSeekTime) => {
    if (player.value && player.value.readyState() > 0) {
      player.value.currentTime(newSeekTime);
    }
  }
);

const togglePlay = () => {
  if (player.value) {
    player.value.paused() ? player.value.play() : player.value.pause();
  }
};

const toggleMute = () => {
  if (player.value) {
    player.value.muted(!player.value.muted());
    isMuted.value = player.value.muted();
  }
};

const seekVideo = () => {
  if (player.value) {
    player.value.currentTime(seekTime.value);
  }
};
</script>

<style scoped>

.video-container {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  color: white;
}
.seek-bar {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: transparent;
  outline: none;
}

.seek-bar::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background: #ddd;
}

.seek-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -4px;
}
.custom-video {
  background: transparent !important;
}

.vjs-has-started .vjs-control-bar,
.vjs-audio-only-mode .vjs-control-bar {
  display: none !important;
}
.vjs-title-bar,
.vjs-big-play-button,
.vjs-loading-spinner,
.vjs-error-display,
.vjs-text-track-display,
.vjs-poster {
  display: none !important;
}

</style>
