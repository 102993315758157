<template>
  <div class="list-event relative h-full overflow-hidden">
    <div
      class="gap-[20px] px-[24px] py-[20px] bg-ems-gray800 border-1px border-solid border-ems-tag1 border-t-0"
    >
      <div class="grid grid-cols-14 items-center gap-[20px]">
        <SelectSearchList
          class="col-span-2"
          :placeholder="t('object_tracing.cam-group')"
          :options="groupCameras"
          v-model:value="formState.groupCameraId"
          :allow-clear="true"
          :label-prop="'name'"
          :value-prop="'id'"
          @change="changeGroupCamera"
          :isLabelI18n="false"
        />
        <SelectSearchList
          class="col-span-2 !h-[39px]"
          :placeholder="t('object_information.camera')"
          :options="cameras"
          isShowSearch
          multiple
          v-model:value="formState.listCamera"
          :allow-clear="true"
          :label-prop="'name'"
          :value-prop="'id'"
          :isLabelI18n="false"
          :maxTagCount="1"
          :max-tag-text-length="20"
        />
        <SelectSearchList
          class="col-span-2 !h-[39px]"
          :placeholder="t('weapon_event.type_weapon')"
          :options="TYPE_OBJECT_WEAPON"
          isShowSearch
          v-model:value="formState.typeObject"
          :allow-clear="true"
          :isLabelI18n="true"
          :maxTagCount="1"
          :max-tag-text-length="20"
        />
        <div class="col-span-2 recognition">
          <a-input-number
            v-model:value="formState.score"
            class="w-full"
            :min="1"
            :max="100"
            addon-after="%"
            :placeholder="`${t('weapon_event.recognition_threshold')}`"
            @focus="selectText"
            allow-clear
          />
        </div>
        <div class="col-span-2 range_time">
          <a-range-picker
            v-model:value="formState.dateRange"
            :placeholder="[t('common.from-date'), t('common.to-date')]"
            allow-clear
            format="DD/MM/YYYY HH:mm"
            show-time
          />
        </div>
        <div class="col-span-4">
          <clip-button
            class="flex items-center text-sm text-ems-white text-nowrap h-[39px] !px-[30px] w-[fit-content] justify-self-end"
            :type="'secondary'"
            @click="resetData"
          >
            <cds-feather-icons
              type="rotate-cw"
              size="16"
              class="!text-ems-white mr-2"
            />
            {{ $t('common.refresh') }}
          </clip-button>
        </div>
      </div>
    </div>

    <a-spin :spinning="isLoading">
      <Empty
        v-if="!listEvent || listEvent.length === 0"
        :description="t('common.no_data_displayed')"
        class="pt-[200px] mx-auto 2xl:col-span-6 col-span-5"
      />
      <div
        v-else
        class="grid grid-cols-10 gap-[20px] px-[20px] py-[16px] bg-ems-gray800 h-full"
      >
        <div
          class="2xl:col-span-4 col-span-5 h-full relative p-[16px] bg-ems-gray700 rounded-lg h-full overflow-auto hidden-scroll detail-event"
        >
          <a-spin :spinning="loadingDetail">
            <div class="relative">
              <div
                class="h-full absolute z-3 top-0 left-[34px] flex items-center justify-between"
              >
                <img
                  src="/assets/ems/prev-border.png"
                  alt="img"
                  width="38"
                  height="38"
                  :class="
                    selectedIndex === 0
                      ? 'disabled-icon'
                      : 'cursor-pointer hover:opacity-80'
                  "
                  @click="prevImg()"
                />
              </div>
              <div
                class="absolute z-3 top-0 right-[34px] h-full flex items-center justify-between"
              >
                <img
                  src="/assets/ems/next-border.png"
                  alt="img"
                  width="38"
                  height="38"
                  :class="
                    selectedIndex === total - 1
                      ? 'disabled-icon'
                      : 'cursor-pointer hover:opacity-80'
                  "
                  @click="nextImg()"
                />
              </div>
              <div v-if="item && item.imageUrl" class="relative">
                <Image
                  :height="350"
                  :src="item.imageUrl"
                  class="relative !h-[350px] !w-full object-cover rounded-lg"
                  overlayClassName="popup-confirm-tb-edit"
                >
                  <template #previewMask>
                    <div
                      class="flex gap-20px justify-center items-center h-full rounded-lg"
                    >
                      <cds-feather-icons type="eye" size="20" />
                    </div>
                  </template>
                </Image>
                <a-popover
                  placement="bottom"
                  class="h-[38px] min-w-[124px] absolute bottom-[15px] right-[10px] text-white rounded"
                >
                  <div
                    class="h-[38px] px-[16px] bg-ems-main1 rounded flex items-center justify-center uppercase text-sm font-medium cursor-pointer"
                  >
                    <img
                      src="@/static/img/icon/search_white.svg"
                      alt="PlusIcon"
                      class="w-[15px] h-[15px] mr-[8px]"
                    />
                    {{ t('common.search') }}
                  </div>
                  <template #content>
                    <div class="flex justify-center flex-col space-y-4">
                      <div
                        class="w-full h-[38px] px-[16px] bg-ems-search_event rounded flex text-white gap-[8px] items-center justify-center uppercase text-sm font-medium cursor-pointer"
                        @click="handleSearchImage(item.imageUrl)"
                      >
                        {{ t('weapon_event.search_image') }}
                      </div>
                      <div
                        class="w-full h-[38px] px-[16px] bg-ems-search_profile rounded flex text-white gap-[8px] items-center justify-center uppercase text-sm font-medium cursor-pointer"
                        @click="handleSearchImageProfile(item.imageUrl)"
                      >
                        {{ t('weapon_event.search_image_profile') }}
                      </div>
                    </div>
                  </template>
                </a-popover>
              </div>
              <img
                v-else
                :src="require('@/static/img/no-img-big.png')"
                alt="img"
                class="rounded-[8px] w-full h-[350px] flex-shrink-0 object-cover"
              />
            </div>
            <!-- card left -->
            <div class="mt-[23px]">
              <div
                class="h-[143px] w-[168px] overflow-hidden flex items-center justify-center rounded-lg border-1 border-ems-main2"
              >
                <Image
                  v-if="item && item.imageCropUrl"
                  :src="item.imageCropUrl"
                  class="h-[143px] w-full"
                  overlayClassName="popup-confirm-tb-edit"
                >
                  <template #previewMask>
                    <div
                      class="flex gap-20px justify-center items-center h-full rounded-lg"
                    >
                      <cds-feather-icons type="eye" size="20" />
                    </div>
                  </template>
                </Image>
              </div>
              <div class="flex items-center gap-1 mt-[23px]">
                <span class="text-base font-semibold text-ems-white uppercase"
                  >{{ t('weapon_event.info') }}
                </span>
              </div>
              <div
                class="mt-6px border-b-1px border-solid border-ems-gray600 py-[10px] flex items-center gap-[20px]"
              >
                <span class="text-ems-gray300 w-[150px]">{{
                  t('event_verify.location')
                }}</span>
                <span class="text-ems-gray300 flex justify-end flex-1">{{
                  item ? item.cameraName : ''
                }}</span>
              </div>
              <div
                class="mt-6px border-b-1px border-solid border-ems-gray600 py-[10px] flex items-center gap-[20px]"
              >
                <span class="text-ems-gray300 w-[150px]">{{
                  t('object_information.time')
                }}</span>
                <span
                  v-if="item && item.created_at"
                  class="text-ems-gray300 flex justify-end flex-1"
                  >{{
                    formatDate(item.created_at, 'HH:mm:ss - DD/MM/YYYY')
                  }}</span
                >
              </div>
              <div
                class="mt-6px border-b-1px border-solid border-ems-gray600 py-[10px] flex items-center gap-[20px]"
              >
                <span class="text-ems-gray300 w-[150px]">{{
                  t('weapon_event.name_weapon')
                }}</span>
                <span
                  v-if="item && item.typeObject"
                  class="text-ems-gray300 flex justify-end flex-1"
                  >{{
                    t(`weapon_event.${item.typeObject.toLowerCase()}`)
                  }}</span
                >
              </div>
              <div
                class="mt-6px border-b-1px border-solid border-ems-gray600 py-[10px] flex items-center gap-[20px]"
              >
                <span class="text-ems-gray300 w-[150px]">{{
                  t('weapon_event.recognition_threshold')
                }}</span>
                <span
                  v-if="item && item.score"
                  class="text-ems-boTro600 flex justify-end flex-1"
                  >{{ (item.score * 100).toFixed(2) }}%</span
                >
              </div>
            </div>
          </a-spin>
        </div>

        <div
          class="2xl:col-span-6 col-span-5 max-h-[100%] overflow-auto hidden-scroll pr-1"
          @scroll="onScroll"
          ref="containerEvent"
        >
          <a-spin :spinning="isLoadingList">
            <div
              class="grid 2xl:grid-cols-4 grid-cols-3 gap-[20px] h-[fit-content]"
            >
              <div
                v-for="(data, idx) in listEvent"
                :key="idx"
                class="p-10px rounded-lg bg-ems-gray700 overflow-x-clip cursor-pointer h-[fit-content] min-h-[194px] border-1px border-solid"
                :class="
                  selectedIndex === idx
                    ? ' border-ems-main2'
                    : ' border-transparent'
                "
                :id="selectedIndex === idx ? 'selected-event' : ''"
                @click="clickItem(idx)"
              >
                <div class="relative">
                  <img
                    :src="
                      !data.imageCropUrl
                        ? require('@/static/img/no-img.png')
                        : data.imageCropUrl
                    "
                    class="rounded flex-shrink-0 w-full h-[100px] mb-[8px]"
                  />
                  <div
                    class="text-[12px] text-ems-gray300 font-semibold absolute bottom-[2px] left-[2px] bg-black/20 p-[2px] rounded-sm"
                  >
                    {{ formatDate(data.created_at, 'DD/MM/YYYY - HH:mm:ss') }}
                  </div>
                </div>
                <div
                  class="text-[12px] text-ems-gray300 bg-ems-main1 h-[24px] w-max flex items-center justify-center font-semibold px-[10px] rounded-[18px] mt-[8px]"
                >
                  {{ t(`weapon_event.${data.typeObject.toLowerCase()}`) }}
                </div>
                <div
                  class="text-sm text-ems-gray300 font-semibold uppercase mt-1"
                >
                  {{ data.cameraName }}
                </div>
              </div>
            </div>
          </a-spin>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, reactive, ref, watch, onMounted, onUnmounted } from 'vue';
import Empty from '@/components/empty/index.vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { Image } from 'ant-design-vue';
import { NAVIGATION_TYPE, TYPE_OBJECT_WEAPON,OBJECT_TYPE } from '@/util/common-constant';
import { formatDate, formatDateWeapon } from '@/util/common-utils';
import { debounce } from 'lodash';
import { useRouter } from 'vue-router';
import SelectSearchList from '@/components/select-search-list/SelectSearchList.vue';
const { t } = useI18n();
const { state, dispatch } = useStore();
const groupCameras = computed(() => state.tracingObject.listGroupCamera);
const cameras = computed(() => state.tracingObject.listCameraByGroup);
const listEvent = computed(() => state.weaponEvent.listEventWeapon);
const isLastPage = computed(() => state.tracingObject.isLastPageIntrusion);
const isLoading = ref(false);
const page = ref(0);
const router = useRouter();
const containerEvent = ref(null);
const selectedIndex = ref(0);
const formState = reactive({
  groupCameraId: null,
  size: 20,
  typeObject: null,
  score: null,
  listCamera: [],
  dateFrom: null,
  dateTo: null,
  dateRange: null,
});
onMounted(async () => {
  let promises = [
    dispatch('setTitleHeader', t('weapon_event.header')),
    dispatch('tracingObject/getListGroupCamera'),
    dispatch('ioGroup/getAllGroup'),
  ];
  await Promise.all(promises);
  await changeGroupCamera();
  selectedIndex.value = 0;
  window.addEventListener('keyup', onKeyUp);
});

onUnmounted(() => {
  window.removeEventListener('keyup', onKeyUp);
});

const clickItem = (idx) => {
  loadingDetail.value = true;
  selectedIndex.value = idx;
  setTimeout(() => {
    loadingDetail.value = false;
  }, 300);
};

const onKeyUp = (e) => {
  if (e.keyCode === 37) {
    onNavigate(NAVIGATION_TYPE.PREV);
  } else if (e.keyCode === 39) {
    onNavigate(NAVIGATION_TYPE.NEXT);
  }
};
const total = computed(() => state.tracingObject.totalIntrusion);
const onNavigate = (navigationType) => {
  if (navigationType === NAVIGATION_TYPE.PREV) {
    prevImg();
  } else {
    nextImg();
  }
};

const loadingDetail = ref(false);

const prevImg = async () => {
  if (selectedIndex.value !== 0) {
    loadingDetail.value = true;
    selectedIndex.value--;
    setTimeout(() => {
      let offset = document
        .getElementById('selected-event')
        .getBoundingClientRect();
      if (
        containerEvent.value &&
        containerEvent.value.scrollTop != 0 &&
        offset.top < 220
      ) {
        containerEvent.value.scroll({
          top: containerEvent.value.scrollTop - 500,
          behavior: 'smooth',
        });
      }
      loadingDetail.value = false;
    }, 200);
  }
};
const isLoadingList = ref(false);
const nextImg = async () => {
  loadingDetail.value = true;
  if (selectedIndex.value !== total.value - 1) {
    if (selectedIndex.value === listEvent.value.length - 1) {
      isLoadingList.value = true;
      page.value += 1;
      let params = {
        ...formState,
        page: page.value,
      };
      await dispatch('weaponEvent/getEventWeapon', {
        params: params,
        reset: false,
      });
      isLoadingList.value = false;
    } else selectedIndex.value++;
  }
  setTimeout(() => {
    let offset = document
      .getElementById('selected-event')
      .getBoundingClientRect();
    if (
      containerEvent.value &&
      offset.top > containerEvent.value.offsetHeight
    ) {
      containerEvent.value.scroll({
        top: containerEvent.value.scrollTop + offset.top - 250,
        behavior: 'smooth',
      });
    }
    loadingDetail.value = false;
  }, 200);
};

const item = computed(() => {
  return listEvent.value[selectedIndex.value]
    ? listEvent.value[selectedIndex.value]
    : {};
});

const changeGroupCamera = async () => {
  formState.listCamera = [];
  await dispatch(
    'tracingObject/getListCameraByGroups',
    formState.groupCameraId ? [formState.groupCameraId] : []
  );
};
const resetData = () => {
  formState.groupCameraId = null;
  formState.typeObject = null;
  formState.score = null;
  formState.dateFrom = null;
  formState.dateTo = null;
  formState.dateRange = null;
  formState.listCamera = [];
};
const getData = async () => {
  isLoading.value = true;
  page.value = 0;
  formState.dateFrom = formState.dateRange ? formState.dateRange[0] : null;
  formState.dateTo = formState.dateRange ? formState.dateRange[1] : null;
  let params = {
    cameraIds: formState.listCamera.join(','),
    groupCameraId: formState.groupCameraId,
    score: formState.score / 100,
    typeObject: formState.typeObject,
    page: page.value,
    size: formState.size,
    dateFrom: formState.dateFrom
      ? formatDateWeapon(formState.dateFrom, 'yyyy-MM-dd HH:mm:ss')
      : null,
    dateTo: formState.dateTo
      ? formatDateWeapon(formState.dateTo, 'yyyy-MM-dd HH:mm:ss')
      : null,
  };
  await dispatch('weaponEvent/getEventWeapon', {
    params: params,
    reset: true,
  });
  isLoading.value = false;
};
const onScroll = async ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (scrollTop + clientHeight >= scrollHeight - 1 && !isLastPage.value) {
    isLoadingList.value = true;
    page.value += 1;
    let params = {
      ...formState,
      page: page.value,
    };
    await dispatch('weaponEvent/getEventWeapon', {
      params: params,
      reset: false,
    });
    isLoadingList.value = false;
  }
};

watch(
  () => formState,
  debounce(async () => {
    loadingDetail.value = true;
    await getData();
    selectedIndex.value = 0;
    if (containerEvent.value) {
      containerEvent.value.scroll({ top: 0, behavior: 'smooth' });
    }
    setTimeout(() => {
      loadingDetail.value = false;
    }, 300);
  }, 300),
  {
    deep: true,
  }
);
const handleSearchImage = (image) => {
  router.push({
    path: '/trace-management/identification-event',
    query: {
      searchImage: image,
    },
  });
};
const handleSearchImageProfile = (image) => {
  router.push({
    path: '/io-management/object-management',
    query: {
      searchImage: image,
      searchType:OBJECT_TYPE.HUMAN
    },
  });
};
</script>

<style lang="scss">
.list-event .detail-event .ant-spin-nested-loading {
  height: 100% !important;
}
.list-event .ant-spin-nested-loading {
  height: calc(100% - 80px);
}
.list-event .ant-spin-container {
  height: 100%;
}
.list-event .disabled-icon {
  cursor: not-allowed;
}
.list-event .ant-select-selector {
  height: 39px !important;
  padding-right: 70px !important;
}
.list-event .ant-select-selector .ant-select-selection-overflow {
  flex-wrap: nowrap;
  height: 39px !important;
  align-items: center;
  transform: translateY(-7px) !important;
}
.list-event .from-to-date .ant-row {
  margin: 0 !important;
}
.list-event .from-to-date .ant-row .ant-col:first-child {
  padding-left: 0 !important;
}
.list-event .from-to-date .ant-row .ant-col:last-child {
  padding-right: 0 !important;
}
.list-event .from-to-date .ant-row .ant-picker {
  border-radius: 4px !important;
  height: 34px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-style: none;
}
.list-event .from-to-date .ant-row .ant-form-item-control-input {
  min-height: unset !important;
}
.list-event .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 0px;
  top: 0px;
  right: 30px;
}
.list-event ::-webkit-scrollbar:horizontal {
  height: 3px;
}
.list-event .ant-image {
  width: 100% !important;
}
.list-event .ant-image-mask {
  border-radius: 0.5rem;
}
.ant-image-preview-img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.popup-view-more .ant-popover-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 233, 233, var(--tw-bg-opacity));
  border-radius: 8px;
  padding: 0px;
}
.popup-view-more .ant-popover-arrow-content {
  background-color: #e9e9e9 !important;
}
.recognition .ant-input-number {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(68, 68, 89, var(--tw-border-opacity));
  border-width: 1px !important;
  border-style: solid;
  color: white;
  border-radius: 4px;
  overflow: hidden;
}
.recognition .ant-input-number-input {
  height: 39px;
}
.recognition .ant-input-number-group-addon {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(68, 68, 89, var(--tw-border-opacity));
  color: white;
  border-width: 1px !important;
  border-style: solid;
}
.recognition .ant-input-number-handler-wrap {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
}
.recognition .ant-input-number-input::placeholder {
  color: #706F80 !important;
}
.range_time .ant-picker {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border: 1px solid #444459 !important;
  height: 39px !important;
}
</style>
