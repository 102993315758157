<template>
  <cds-main class="list-ident-event flex flex-col h-full">
    <div
      class="pl-[16px] pr-[20px] py-[14px] grid 2xl:grid-cols-12 grid-cols-7 gap-[20px] bg-ems-gray900 flex-shrink-0"
    >
      <div
        v-if="route.query.idTracing && route.query.tab"
        class="flex items-center hover:cursor-pointer text-ems-main1 hover:text-ems-main2"
        @click="onBack"
      >
        <div class="p-2">
          <img
            src="/assets/ems/arrow-left.png"
            alt="left"
            class="block mx-auto"
            width="8"
            height="4"
          />
        </div>
        <span class="font-bold">{{ t('common.comeback') }}</span>
      </div>
      <div class="">
        <input-search-event
          v-model:value="formState.name"
          size="small"
          :placeholder="$t('common.search')"
          :has-search-advanced="false"
          :maxlength="255"
          @press-enter="onSearch"
          @change="onDelaySearch"
          @onSearchAdvanced="onSearchAdvanced"
        />
      </div>
      <div class="">
        <SelectSearchList
          ref="type"
          :options="OBJECT_TYPE_TEXT_VALUE"
          v-model:value="formState.type"
          :is-show-search="true"
          :placeholder="$t('object_tracing.object_type')"
          @change="onSearch"
        />
      </div>
      <div class="">
        <SelectSearchList
          ref="type"
          :multiple="true"
          :options="listGroupCamera"
          :label-prop="'name'"
          :value-prop="'id'"
          :is-label-i18n="false"
          v-model:value="formState.groupCameraIds"
          :allow-clear="true"
          :max-tag-count="1"
          :max-tag-text-length="5"
          :placeholder="$t('identification-event.group_camera')"
          @change="onChangeGroupCamera"
        />
      </div>
      <div class="col-span-3">
        <SelectSearchList
          ref="type"
          :multiple="true"
          :options="listCameraByGroup"
          :label-prop="'name'"
          :value-prop="'id'"
          :is-label-i18n="false"
          v-model:value="formState.cameraIds"
          :allow-clear="true"
          :max-tag-count="1"
          :max-tag-text-length="5"
          :placeholder="$t('identification-event.camera')"
          @change="onSearch"
        />
      </div>
      <div class="h-39px relative">
        <UploadSearchList
          v-model:value="formState.image"
          name="urlImg"
          @on-clear="onClearImg"
          :no-name="true"
          class="max-w-full"
        ></UploadSearchList>
      </div>
      <div
        class="col-span-3 flex 2xl:col-span-5 2xl:justify-end justify-start items-center gap-[16px] shrink-0"
      >
        <clip-button
          type="reset"
          class="flex items-center justify-center gap-1 whitespace-nowrap"
          @click="onSearchAdvanced"
        >
          {{ $t('advanced_search') }}
        </clip-button>
        <cds-search-form-button
          class="!pb-0"
          :buttons="buttonsForm"
          @onClear="refresh"
          @onDelete="onDelete"
        />
      </div>
    </div>
    <div
      v-if="isShowDataAva"
      class="bg-ems-gray900 px-[16px] pb-2 flex-shrink-0 pt-2"
    >
      <div
        class="flex items-center gap-[21px] pb-5px overflow-x-auto hidden-scroll border-b-1 border-ems-tag1"
      >
        <a-popover
          placement="bottom"
          overlay-class-name="popover-image"
          v-for="(item, index) in listAvatarObj"
          :key="item"
        >
          <template #content>
            <div v-if="item.imgDraw">
              <img
                :src="item.imgDraw"
                :alt="item.imgDraw"
                class="max-h-210px max-w-355px w-full h-full flex items-center rounded-4px border-1px border-ems-gray500"
              />
            </div>
          </template>
          <img
            :src="item.imgCrop"
            @click="onPickAvatar(index)"
            :class="item.isPick ? classAvatar : classAvatarDefault"
            alt=""
          />
        </a-popover>
      </div>
    </div>
    <div
      class="bg-ems-gray900 w-full h-[90px] flex items-center px-3 pb-1"
      v-if="isShowDataAva"
    >
      <div v-if="isShowDataImg" id="container-caro" class="flex w-full gap-2">
        <img
          :class="buttonBefore"
          src="@/static/img/icon/go-before.svg"
          alt=""
          @click="onShowBefore"
        />
        <div class="overflow-hidden">
          <div
            id="caro"
            class="flex w-full gap-2 transition-custom-event"
            :style="style"
          >
            <div v-for="(item, index) in dataIOs" :key="index">
              <div
                :class="item.isPick ? classTag : classTagDefault"
                @click="onClickTag(item, index)"
              >
                <div class="col-span-2 flex items-center gap-[12px]">
                  <div class="flex-shrink-0">
                    <a-popover
                      placement="leftTop"
                      :arrow="false"
                      overlay-class-name="popover-image"
                    >
                      <template #content>
                        <div v-if="item.imagesAvatar">
                          <img
                            :src="item.imagesAvatar"
                            :alt="item.imagesAvatar"
                            class="max-h-210px max-w-355px w-full h-full flex items-center rounded-4px border-1px border-ems-gray500"
                          />
                        </div>
                      </template>
                      <img
                        class="w-[30px] h-[30px] rounded-[28px] object-cover"
                        :src="item.imagesAvatar"
                        alt=""
                      />
                    </a-popover>
                  </div>
                  <div>
                    <a-popover
                      placement="bottom"
                      :arrow="false"
                      overlay-class-name="popover-id"
                    >
                      <template #content>
                        <div v-if="item.id">
                          <span class="text-white">{{
                            item.name ? item.name : item.id
                          }}</span>
                        </div>
                      </template>
                      <span
                        class="text-white font-semibold text-[16px] !w-[90px] truncate block"
                        >{{ item.name ? item.name : item.id }}</span
                      >
                    </a-popover>
                  </div>
                </div>
                <span
                  class="text-ems-boTro3_300 items-center flex justify-end"
                  >{{ `${item.confidence}%` }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <!--        <img-->
        <!--          class="cursor-pointer"-->
        <!--          src="@/static/img/icon/reset-red.svg"-->
        <!--          alt=""-->
        <!--          @click="onResetSearchAva"-->
        <!--        />-->
        <img
          :class="buttonAfter"
          src="@/static/img/icon/go-after.svg"
          alt=""
          @click="onShowNext"
        />
      </div>
      <div v-else id="container-caro" class="flex w-full gap-2">
        <span class="text-white">{{ t('common.empty-table') }}</span>
      </div>
    </div>
    <!-- <cds-cards title="" class="h-full full-height-card m-0">

    </cds-cards> -->
    <div v-if="!showTb || loadingSearchImg" class="spin bg-transparent h-300px">
      <a-spin />
    </div>
    <div
      class="table-custom flex-1 overflow-auto relative"
      ref="containerEvent"
      @scroll="handleScroll"
      v-else
    >
      <a-table
        ref="table"
        :pagination="false"
        :data-source="listEvent"
        :loading="isLoading"
        :columns="columns"
        :rowSelection="rowSelection"
      >
        <template #emptyText>
          <Empty
            :description="t('identity_object.table.empty_table')"
            class="mt-10"
          />
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action' && record">
            <div class="flex justify-center">
              <ActionButton
                :buttons="buttonActions"
                @onDelete="onDeleteRow(record)"
                @onView="onView(record)"
              />
            </div>
          </template>

          <template v-if="column.key === 'status' && record">
            <span
              v-if="
                record.status === EVENT_STATUS_VALUE.CHECKIN ||
                record.status === EVENT_STATUS_VALUE.ACCESS_CONTROL_IN
              "
              class="px-[10px] py-[2px] inline-block border-[1px] border-solid rounded-4px"
              :class="'text-ems-boTro500 border-ems-boTro500'"
              >{{ t('identification-event.checkin') }}</span
            >
            <span
              v-if="
                record.status === EVENT_STATUS_VALUE.CHECKOUT ||
                record.status === EVENT_STATUS_VALUE.ACCESS_CONTROL_OUT
              "
              class="px-[10px] py-[2px] inline-block border-[1px] border-solid rounded-4px"
              :class="'text-ems-boTro4_600 border-ems-boTro4_600'"
              >{{ t('identification-event.checkout') }}</span
            >
            <span
              v-if="record.status === EVENT_STATUS_VALUE.APPEAR"
              class="px-[10px] py-[2px] inline-block border-[1px] border-solid rounded-4px"
              :class="'text-ems-boTro2_600 border-ems-boTro2_600'"
              >{{ t('identification-event.appear') }}</span
            >
            <span
              v-if="record.status === EVENT_STATUS_VALUE.INTRUSION"
              class="px-[10px] py-[2px] inline-block border-[1px] border-solid rounded-4px"
              :class="'text-ems-main1 border-ems-main1'"
              >{{ t('identification-event.intrusion') }}</span
            >
          </template>
          <template v-if="column.key === 'ioId' && record">
            <div
              @click="onRouterObj(record)"
              class="cursor-pointer text-ems-main4 hover:underline"
            >
              <a-tooltip
                v-if="record.name"
                placement="bottom"
                :title="`${t('common.view')} ${t(
                  'object_information.ident-history'
                )}`"
                overlay-class-name="tooltip-view-more"
                ><span>{{ record.name }}</span></a-tooltip
              >
              <template v-else-if="record.ioId.length > 10">
                <a-tooltip :title="record.name ? record.name : record.ioId">
                  <span>{{ record.ioId.substring(0, 10) + '...' }}</span>
                </a-tooltip>
              </template>
              <a-tooltip
                v-else
                placement="bottom"
                :title="`${t('common.view')} ${t(
                  'object_information.ident-history'
                )}`"
                overlay-class-name="tooltip-view-more"
              >
                <span>{{ record.ioId }} </span>
              </a-tooltip>
            </div>
          </template>
          <template v-if="column.key === 'imageUrl' && record">
            <a-popover
              placement="right"
              color="#000"
              overlay-class-name="popup-image"
            >
              <template #content>
                <PopupContent :record="record" />
              </template>
              <div class="w-[62px] h-[62px] img-custom">
                <img
                  :src="
                    record.type === TYPE_TEXT.VEHICLE
                      ? checkNoImage(record.imageLicensePlateUrl)
                      : checkNoImage(record.imageUrl)
                  "
                  @click="onView(record)"
                  :alt="record.name"
                  class="cursor-pointer w-full h-full"
                />
              </div>
            </a-popover>
          </template>
          <template v-if="column.key === 'warning' && record">
            <span
              v-if="record.warning"
              class="px-[10px] py-[2px] inline-block rounded-4px"
              :class="'text-ems-white bg-ems-main2'"
              >{{ t('identification-event.warning') }}</span
            >
            <span
              v-else
              class="px-[10px] py-[2px] inline-block rounded-4px"
              :class="'text-ems-white bg-ems-gray600'"
              >{{ t('identification-event.normal') }}</span
            >
          </template>
          <template v-if="column.key === 'time' && record">
            <span>{{
              formatDate(record.time, 'hh:mm:ss A - DD/MM/YYYY')
            }}</span>
          </template>
          <template v-if="column.key === 'area' && record">
            <a-tooltip
              placement="bottom"
              :title="t('common.filter-by-camera')"
              overlay-class-name="tooltip-view-more"
            >
              <span
                class="cursor-pointer hover:text-ems-main1 hover:underline"
                @click="selectArea(record)"
                >{{ record.cameraName }} - {{ record.cameraLocation }}</span
              >
            </a-tooltip>
          </template>
          <template v-if="column.key === 'ioGroups' && record">
            <div
              class="flex items-center flex-wrap gap-y-1"
              v-if="record.ioGroups"
            >
              <div
                class="flex-shrink-0"
                v-for="(item, index) in record.ioGroups"
                :key="index"
              >
                <span
                  class="inline-block rounded-lg py-6px px-10px bg-ems-tag1 text-center mr-2 text-ems-gray200"
                  v-if="index < 2"
                >
                  {{
                    listGroupKeyValue[item] &&
                    listGroupKeyValue[item].length > 20
                      ? `${listGroupKeyValue[item].substring(0, 20)}...`
                      : listGroupKeyValue[item]
                  }}
                </span>
              </div>
              <a-popover
                placement="top"
                overlay-class-name="popup-groups"
                v-if="record.ioGroups.length > 2"
              >
                <template #content>
                  <div>
                    <span
                      class="inline-block rounded-lg py-[6px] text-ems-gray300 text-sm px-3 bg-ems-tag1 uppercase mr-1 mb-2"
                      v-for="(item, idx) in record.ioGroups"
                      :key="idx"
                    >
                      {{ listGroupKeyValue[item] }}
                    </span>
                  </div>
                </template>
                <span
                  class="inline-block rounded-lg p-1 px-2 bg-ems-tag1 text-ems-gray200"
                >
                  ...
                </span>
              </a-popover>
            </div>
          </template>
        </template>
      </a-table>
    </div>

    <div
      class="absolute top-[53%] right-0 z-60 icon-expand-drawer cursor-pointer"
      @click="openDrawer = true"
      v-if="isSave"
    />
  </cds-main>
  <drawer-custom
    :visible="openDrawer"
    :width="450"
    @onClose="onClose"
    @onCancel="openDrawer = false"
    :component="SearchAdvanced"
    :form-state="formState"
    @onSearchAdvanced="searchData"
    @resetCameraId="onResetCameraId"
    :show-btn-search="true"
    @onHide="onHide"
  />
  <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
</template>

<script setup>
import {
  computed,
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue';
// import ConstantAPI from '@/config/ConstantAPI';
import { useStore } from 'vuex';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useI18n } from 'vue-i18n';
import ActionButton from '@/components/action-button';
import InputSearchEvent from '@/components/input-search-event/index.vue';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import {
  formatDate,
  getText,
  hasPermEdit,
  trim,
  checkNoImage,
} from '@/util/common-utils';
import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'lodash';
// import ConstantAPI from '../../../config/ConstantAPI';
import {
  defaultButton,
  EVENT_STATUS_VALUE,
  FORM_MODE,
  OBJECT_TYPE,
  TYPE_TEXT,
  OBJECT_TYPE_TEXT_VALUE,
} from '../../../util/common-constant';
import { notification } from 'ant-design-vue';
import PopupContent from './components/PopupContent.vue';
import SelectSearchList from '@/components/select-search-list/SelectSearchList.vue';
import UploadSearchList from '@/components/upload-search-list/UploadSearchList.vue';
import Empty from '@/components/empty/index.vue';
import dayjs from 'dayjs';

const isLastPage = computed(() => state.identificationEvent.isLastPage);
const listGroupCamera = computed(() => state.tracingObject.listGroupCamera);
const listCameraByGroup = computed(() => state.tracingObject.listCameraByGroup);
const pageSize = ref(10);
onMounted(async () => {
  await dispatch('listSelect/getAllColor');
  await dispatch('listSelect/getAllVehicleType');
  if (route.query.eventId && route.query.objectId) {
    dispatch('setTitleHeader', t('identification-event.header-1'));
  } else if (route.query.isTracing) {
    dispatch('setTitleHeader', t('identification-event.header-2'));
  } else {
    dispatch('setTitleHeader', t('identification-event.header'));
  }
  if (route.query.page) {
    page.value = parseInt(route.query.page);
  }
  formState.name = route.query.searchName ? route.query.searchName : null;
  formState.type = route.query.searchType
    ? route.query.searchType
    : TYPE_TEXT.VEHICLE;
  pageSize.value = route.query.size ? Number(route.query.size) : 10;
  if (route.query.searchGroupId) {
    formState.groupId =
      typeof route.query.searchGroupId === 'string'
        ? [Number(route.query.searchGroupId)]
        : route.query.searchGroupId.map((item) => Number(item));
  } else {
    formState.groupId = [];
  }
  formState.status = route.query.searchStatus ? route.query.searchStatus : null;
  formState.warning = route.query.searchIsWarningConfig
    ? route.query.searchIsWarningConfig === 'true'
    : null;
  if (route.query.searchCameraGroup) {
    formState.groupCameraIds =
      typeof route.query.searchCameraGroup === 'string'
        ? [route.query.searchCameraGroup]
        : [...route.query.searchCameraGroup];
  } else {
    formState.groupCameraIds = [];
  }
  const getListCameraSuccess = await dispatch(
    'tracingObject/getListCameraByGroups',
    formState.groupCameraIds
  );

  if (getListCameraSuccess && route.query.searchCamera) {
    formState.cameraIds =
      typeof route.query.searchCamera === 'string'
        ? [route.query.searchCamera]
        : [...route.query.searchCamera];
  } else {
    formState.cameraIds = [];
  }

  formState.dateFrom = route.query.searchDateFrom
    ? new Date(route.query.searchDateFrom)
    : null;
  formState.dateTo = route.query.searchDateTo
    ? new Date(route.query.searchDateTo)
    : null;
  formState.image = route.query.searchImage ? route.query.searchImage : null;
  formState.eventId = route.query.eventId ? route.query.eventId : null;
  formState.objectId = route.query.objectId ? route.query.objectId : null;
  formState.age = route.query.searchAge ? route.query.searchAge : null;
  formState.gender = route.query.searchGender
    ? Number(route.query.searchGender)
    : null;
  formState.race = route.query.searchRace ? route.query.searchRace : null;
  formState.vehicleBrand = route.query.searchVehicleBrand
    ? route.query.searchVehicleBrand
    : null;
  formState.vehicleName = route.query.searchVehicleName
    ? route.query.searchVehicleName
    : null;
  formState.vehicleColor = route.query.searchVehicleColor
    ? route.query.searchVehicleColor
    : null;
  formState.IdsSelected = route.query.searchIdsSelected
    ? route.query.searchIdsSelected
    : null;
  dispatch('ioGroup/getAllGroup');
  dispatch('tracingObject/getListGroupCamera');
  dispatch('tracingObject/getListCameraByGroups', formState.groupCameraIds);
  showTb.value = true;
  let promises = [
    dispatch('listSelect/getAllColor'),
    dispatch('listSelect/getAllAge'),
    dispatch('listSelect/getAllRace'),
    dispatch('listSelect/getAllBrand'),
    dispatch('listSelect/getAllVehicleType'),
  ];
  Promise.all(promises);
  await dispatch('identificationEvent/getAll', formState);
  window.addEventListener('resize', onHandleWidth);
});
const showTb = ref(false);
const page = ref(1);
const DrawerCustom = defineAsyncComponent(() =>
  import('@/components/drawer-custom/index.vue')
);
const SearchAdvanced = defineAsyncComponent(() =>
  import('./components/SearchAdvanced.vue')
);
const { t } = useI18n();
const DEBOUNCE_TIME = 300;
const route = useRoute();
const router = useRouter();
const columns = ref([
  {
    title: t('identification-event.id'),
    dataIndex: 'ioId',
    key: 'ioId',
    width: 150,
  },
  {
    title: t('identification-event.img'),
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    width: 150,
  },
  {
    title: t('identification-event.object_type'),
    dataIndex: 'type',
    key: 'type',
    width: 150,
    customRender({ record }) {
      return t(getText(record.type, OBJECT_TYPE_TEXT_VALUE));
    },
  },
  {
    title: t('identification-event.area'),
    dataIndex: 'area',
    key: 'area',
    width: 150,
  },
  {
    title: t('identification-event.time'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 200,
    customRender({ record }) {
      return formatDate(record.createdAt);
    },
  },
  {
    title: t('identification-event.status'),
    dataIndex: 'status',
    key: 'status',
    width: 150,
  },
  {
    title: t('identification-event.type'),
    dataIndex: 'warning',
    key: 'warning',
    width: 150,
  },
  {
    title: t('identification-event.ioGroups'),
    dataIndex: 'ioGroups',
    key: 'ioGroups',
    width: 300,
  },
  {
    title: t('common.action'),
    dataIndex: 'action',
    key: 'action',
    width: 150,
    align: 'center',
    fixed: 'right',
  },
]);
const { buttonsForm, buttonActions } = defaultButton('event-identification');
const { state, dispatch } = useStore();
const perms = computed(() => state.auth.userInfo.permissions);
const openDrawer = ref(false);
const isSave = ref(false);
const table = ref(null);
const listGroupKeyValue = computed(() => state.ioGroup.listGroupKeyValue);
const containerEvent = ref();
const listEvent = computed(() =>
  state.identificationEvent.listEvent.map((e) => ({ ...e, key: e.id }))
);
const isLoading = ref(false);
let selectedRows = ref([]);
const rowSelection = ref({
  onSelect: (record, selected, rows) => {
    selectedRows.value = rows;
  },
  onSelectAll: (selected, rows) => {
    selectedRows.value = rows;
  },
  onChange: (selectedRowKeys) => {
    selectedRows.value = selectedRowKeys;
  },
});
let formState = reactive({
  name: '',
  type: TYPE_TEXT.VEHICLE,
  groupId: [],
  warning: null,
  status: null,
  groupCameraIds: [],
  cameraIds: [],
  dateFrom: null,
  dateTo: null,
  image: null,
  eventId: null,
  objectId: null,
  age: null,
  gender: null,
  race: null,
  vehicleBrand: null,
  vehicleName: null,
  vehicleColor: null,
  percent: null,
  IdsSelected: null,
  reset: true,
  page: 1,
  size: 15,
});
const onHide = () => {
  openDrawer.value = false;
  isSave.value = true;
};
const onSearch = async (reset = true, page = 1) => {
  if (showTb.value) {
    isLoading.value = true;
    formState.dateFrom = formState.dateFrom
      ? dayjs(formState.dateFrom).startOf('minute').toDate()
      : null;
    formState.dateTo = formState.dateTo
      ? dayjs(formState.dateTo).endOf('minute').toDate()
      : null;
    formState.page = page;
    formState.reset = reset;
    await dispatch('identificationEvent/getAll', formState);
    isLoading.value = false;
    router.push({
      query: {
        ...route.query,
        searchName: trim(formState.name),
        searchType: formState.type,
        searchGroupId: formState.groupId,
        searchIsWarningConfig: formState.warning,
        searchStatus: formState.status,
        searchCamera: formState.cameraIds,
        searchCameraGroup: formState.groupCameraIds,
        searchDateFrom: formState.dateFrom,
        searchDateTo: formState.dateTo,
        searchImage: formState.image,
        searchAge: formState.age,
        searchGender: formState.gender,
        searchRace: formState.race,
        searchVehicleBrand: formState.vehicleBrand,
        searchVehicleName: formState.vehicleName,
        searchVehicleColor: formState.vehicleColor,
        searchIdsSelected:
          dataIOs.value.length && !dataPick.value.length
            ? dataIOs.value.map((e) => e.id).join(',')
            : formState.IdsSelected,
        page: table.value.page,
        size: table.value.size,
      },
    });
  }
};

const onDelaySearch = debounce(() => {
  onSearch();
}, DEBOUNCE_TIME);

const handleScroll = ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (scrollTop + clientHeight >= scrollHeight - 1 && !isLastPage.value) {
    onSearch(false, formState.page + 1);
  }
};

const onView = (row) => {
  router.push({
    path: '/trace-management/identification-event/detail',
    query: {
      ...route.query,
      page: table.value.page,
      id: row.id,
    },
  });
};
const onRouterObj = (row) => {
  router.push({
    name: '/io-management/object-management/edit',
    query: {
      id: row.ioId,
      type: OBJECT_TYPE[row.type],
      mode: hasPermEdit(perms.value, 'object')
        ? FORM_MODE.UPDATE
        : FORM_MODE.VIEW,
      tab: 'history',
    },
  });
};

const onDelete = async () => {
  const listId = selectedRows.value;
  if (listId.length < 1) {
    notification.error({
      message: t('common.notification'),
      description: t('common.no_record_selected'),
      duration: 4,
    });
    return;
  }
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    dispatch('identificationEvent/delete', listId).then(() => {
      onSearch(true);
    });
  }
};
const showConfirmCustom = ref(null);
const onDeleteRow = async (row) => {
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    dispatch('identificationEvent/delete', [row]).then(() => {
      onSearch(true);
    });
  }
};
const refresh = async () => {
  resetForm();
  formState.eventId = null;
  formState.objectId = null;
  if (containerEvent.value) {
    containerEvent.value.scroll({ top: 0, behavior: 'smooth' });
  }
  await onSearch(true);
  router.push({
    query: {},
  });
  onClearImg();
};

const onClose = (isSaveData) => {
  if (!isSaveData) {
    resetForm(false);
    router.push({
      query: {
        ...route.query,
        searchName: formState.name,
        searchType: formState.type,
        searchGroupId: formState.groupId,
        searchIsWarningConfig: formState.warning,
        searchStatus: formState.status,
        searchCamera: formState.cameraIds,
        searchCameraGroup: formState.groupCameraIds,
        searchDateFrom: formState.dateFrom,
        searchDateTo: formState.dateTo,
      },
    });
  }
  isSave.value = isSaveData;
  openDrawer.value = false;
  table.value.fetchData(true);
};
const resetForm = (isClearImg = true) => {
  formState.name = '';
  formState.type = TYPE_TEXT.VEHICLE;
  formState.groupId = [];
  formState.warning = null;
  formState.status = null;
  formState.cameraIds = [];
  formState.groupCameraIds = [];
  formState.dateFrom = null;
  formState.dateTo = null;
  formState.age = null;
  formState.gender = null;
  formState.race = null;
  formState.vehicleBrand = null;
  formState.vehicleName = null;
  formState.vehicleColor = null;
  if (isClearImg) formState.image = null;
};
const onResetCameraId = () => {
  formState.cameraIds = [];
  router.push({
    query: {
      ...route.query,
      searchCamera: formState.cameraIds,
    },
  });
};
const onChangeGroupCamera = async () => {
  await onSearch();
  await dispatch(
    'tracingObject/getListCameraByGroups',
    formState.groupCameraIds
  );
  onResetCameraId();
};
const onSearchAdvanced = () => {
  openDrawer.value = true;
};

const searchData = () => {
  onSearch();
  openDrawer.value = false;
  isSave.value = true;
};

const selectArea = (data) => {
  formState.cameraIds = [data.cameraId];
  onSearch();
};

watch(
  () => formState.image,
  (val) => {
    dispatch('identificationEvent/getObjectByImage', val).then(() => {
      showTb.value = false;
      if (val) {
        if (dataIOs.value.length) {
          formState.IdsSelected = dataIOs.value.map((e) => e.id).join(',');
        } else {
          formState.IdsSelected = null;
        }
        router.push({
          query: {
            ...route.query,
            searchImage: formState.image,
          },
        });
        onHandleWidth();
        setTimeout(() => {
          onSearch();
        }, 100);
      } else {
        dataPick.value = [];
        router.push({
          query: {
            ...route.query,
            searchImage: formState.image,
            searchIdsSelected: null,
          },
        });
      }
      setTimeout(() => {
        showTb.value = true;
      }, 500);
    });
  }
);

const onClearImg = () => {
  formState.IdsSelected = null;
  dataPick.value = [];
  page.value = 1;
  pageSize.value = 10;
  dispatch('identificationEvent/setDefaultSearchImg');
  onSearch();
};

const dataIOs = computed(
  () => state.identificationEvent.listObjSearchByImgShow
);
const listAvatarObj = computed(() => state.identificationEvent.listAvatarObj);
const isShowDataImg = computed(
  () => state.identificationEvent.listObjSearchByImgShow.length
);
const isShowDataAva = computed(
  () => state.identificationEvent.listAvatarObj.length
);
// const isShowEmptyText = computed(
//   () => state.identificationEvent.isShowEmptyText
// );
const loadingSearchImg = computed(() => state.identificationEvent.loading);
const style = ref(null);
const indexShow = ref(1);
const step = ref(null);
const pxStepShow = ref(null);
const maxIndexShow = ref(0);
const classTagDefault = ref(
  `bg-ems-tag1 flex justify-between h-[39px] px-[12px] w-[223px] rounded cursor-pointer ml-3 gap-3`
);
const classTag = ref(
  `bg-ems-main2 flex justify-between h-[39px] px-[12px] w-[223px] rounded cursor-pointer ml-3 gap-3`
);
const dataPick = ref([]);
const buttonBefore = ref('cursor-not-allowed w-[30px]');
const buttonAfter = ref('cursor-pointer w-[30px] ml-5');

const onClickTag = (item, index) => {
  dataIOs.value[index].isPick = !dataIOs.value[index].isPick;
  if (dataIOs.value[index].isPick) {
    dataPick.value.push(item.id);
  } else {
    dataPick.value = dataPick.value.filter((e) => e !== item.id);
  }
  formState.IdsSelected = dataPick.value.length
    ? dataPick.value.join(',')
    : dataIOs.value.map((e) => e.id).join(',');
  onSearch();
};
const onShowBefore = () => {
  if (pxStepShow.value > 0) {
    pxStepShow.value =
      pxStepShow.value - step.value < 0 ? 0 : pxStepShow.value - step.value;
  }
  style.value = { transform: `translateX(-${pxStepShow.value}px)` };
  if (indexShow.value > 1) {
    indexShow.value = indexShow.value - 1;
    buttonAfter.value = 'cursor-pointer w-[30px] ml-3';
  }
  if (indexShow.value === 1) {
    buttonBefore.value = 'cursor-not-allowed w-[30px]';
  }
};

const onShowNext = () => {
  onHandleWidth();
  if (
    indexShow.value < maxIndexShow.value &&
    document.getElementById('caro').scrollWidth >=
      document.getElementById('container-caro').offsetWidth
  ) {
    pxStepShow.value = pxStepShow.value + step.value;
    style.value = { transform: `translateX(-${pxStepShow.value}px)` };
    indexShow.value = indexShow.value + 1;
    buttonBefore.value = 'cursor-pointer w-[30px]';
  }
  if (indexShow.value === maxIndexShow.value) {
    buttonAfter.value = 'cursor-not-allowed w-[30px] ml-3';
  }
};

const onHandleWidth = () => {
  if (document.getElementById('caro')) {
    const width =
      document.getElementById('caro').scrollWidth / dataIOs.value.length;
    if (document.getElementById('container-caro')) {
      const maxShow =
        dataIOs.value.length +
        1 -
        Number(
          (
            document.getElementById('container-caro').offsetWidth / width
          ).toFixed(0)
        );
      maxShow >= 0 ? (maxIndexShow.value = maxShow) : (maxIndexShow.value = 1);
      if (indexShow.value >= maxIndexShow.value) {
        indexShow.value = maxIndexShow.value === 0 ? 1 : maxIndexShow.value;
        buttonAfter.value = 'cursor-not-allowed w-[30px] ml-3';
      } else {
        buttonAfter.value = 'cursor-pointer w-[30px] ml-3';
      }
      step.value = width;
    }
  }
};

onUnmounted(() => {
  dispatch('identificationEvent/getObjectByImage', null);
  dispatch('identificationEvent/setDefaultSearchImg');
  window.removeEventListener('resize', onHandleWidth);
});

const classAvatarDefault = ref(
  'w-84px h-84px rounded-full flex-shrink-0 object-cover cursor-pointer'
);
const classAvatar = ref(
  'w-84px h-84px rounded-full flex-shrink-0 border-[5px] border-ems-main2 object-cover cursor-pointer'
);
const indexAvaPick = ref(0);
const onPickAvatar = async (index) => {
  pxStepShow.value = 0;
  if (indexAvaPick.value !== index && !table.value.loading) {
    dispatch('identificationEvent/changeAvaPick', index).then(() => {
      style.value = { transform: `translateX(-${pxStepShow.value}px)` };
      if (dataIOs.value.length) {
        // dataPick.value = dataIOs.value.map((e) => e.id);
        formState.IdsSelected = dataIOs.value.map((e) => e.id).join(',');
      } else {
        formState.IdsSelected = null;
      }
      onSearch();
      onHandleWidth();
    });
    indexAvaPick.value = index;
  }
};

// const onResetSearchAva = () => {
//   dispatch('identificationEvent/onResetSearchAva').then((isReload) => {
//     formState.IdsSelected = null
//     dataPick.value = []
//     if (isReload) onSearch();
//   });
// };

const onBack = () => {
  if (route.query.idTracing && route.query.tab) {
    router.push({
      path: '/trace-management/object-tracing/detail',
      query: {
        id: route.query.idTracing,
        tab: route.query.tab,
        subTab: route.query.subTab,
      },
    });
  }
};
</script>

<style lang="scss">
.list-ident-event .ant-table-thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 3;
}
.transition-custom-event {
  transition: all 0.5s ease;
}
.list-ident-event .img-custom img {
  border-radius: 8px;
}
.list-ident-event .img-custom img:hover {
  transition: 0.2s;
  transform: scale(1.1);
}
.list-ident-event .icon-expand-drawer {
  background-image: url('../../../static/img/icon/open-drawer.svg');
  background-size: cover;
  height: 67px;
  width: 19px;
}
.list-ident-event .icon-expand-drawer:hover {
  background-image: url('../../../static/img/icon/open-drawer-active.svg');
}
.popup-image {
  z-index: 1112;
}
.popup-image .ant-popover-inner {
  border: 2px solid #4c4c4c;
  padding: 0;
  width: 771px;
}
.popup-image .ant-popover-arrow {
  display: none;
}
.popup-groups .ant-popover-content {
  box-shadow: 0 0px 20px 0 rgba(255, 255, 255, 0.14) !important;
}
.popup-groups .ant-popover-content .ant-popover-arrow-content {
  background: #1f1f1f !important;
}
.popup-groups .ant-popover-content .ant-popover-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 17, 29, var(--tw-bg-opacity));
  border-radius: 4px;
}
</style>
