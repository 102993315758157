<template>
  <cds-main class="list-ident-event flex flex-col h-full">
    <div
      class="pl-[16px] pr-[20px] py-[14px] flex justify-between items-center gap-10px bg-ems-gray900"
    >
      <div
        class="grid grid-cols-12 flex-wrap justify-start items-center gap-[20px] w-full"
      >
        <div class="col-span-2">
          <input-search
            class="ant-input-sm"
            v-model:value="formState.name"
            size="small"
            :placeholder="$t('common.search')"
            :has-search-advanced="false"
            :maxlength="50"
            @press-enter="onSearch"
            @change="onDelaySearch"
          />
        </div>
        <div class="col-span-2">
          <SelectSearchList
            ref="type"
            :options="OBJECT_TYPE_TEXT"
            v-model:value="formState.type"
            :is-show-search="true"
            :placeholder="$t('object_tracing.object_type')"
            @change="onSearch"
          />
        </div>
        <div class="col-span-2">
          <SelectSearchList
            ref="type"
            :multiple="true"
            :options="listGroupByUser"
            :label-prop="'name'"
            :value-prop="'id'"
            :is-label-i18n="false"
            v-model:value="formState.groupId"
            :allow-clear="true"
            :max-tag-count="1"
            :max-tag-text-length="10"
            :placeholder="$t('object_tracing.group')"
            @change="onSearch"
          />
        </div>
        <div class="h-39px relative">
          <UploadSearchList
            v-model:value="formState.imgUrl"
            @change="onSearch"
            :no-name="true"
            class="max-w-full"
          ></UploadSearchList>
        </div>
        <cds-search-form-button
          class="!pb-0 col-span-5 justify-end"
          :buttons="buttonsForm"
          @onClear="refresh"
          @onWarning="onWarningMulti"
          @onDelete="onDeleteList"
        />
      </div>
    </div>

    <cds-cards title="" class="h-full full-height-card m-0">
      <cds-table
        v-if="showTb"
        ref="table"
        :has-checkbox="true"
        :index-column="false"
        :pagination="true"
        :columns="columns"
        :page-number="page"
        @onRowClicked="
          (record) => {
            onView(record);
          }
        "
        :page-size="size"
        @changePage="onChangePage"
        :api-fetch="apiFetch"
        :param-fetch="formState"
      >
        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'action' && record">
            <div class="flex justify-center">
              <!-- <div class="action-button-custom flex">
                <a-tooltip :title="$t('object_tracing.change_status')">
                  <a-button
                    type="ghost"
                    class="mr-5 text-ems-gray500"
                    @click="onChangeStatus(record)"
                  >
                    <template #icon>
                      <img
                        v-if="record.status"
                        class="w-20px h-20px m-auto"
                        src="../../../static/img/icon/PersonSearchActiveIcon.svg"
                        alt="PersonSearchActiveIcon"
                      />
                      <img
                        v-else
                        class="w-20px h-20px m-auto"
                        src="../../../static/img/icon/PersonSearchIcon.svg"
                        alt="PersonSearchIcon"
                      />
                    </template>
                  </a-button>
                </a-tooltip>
                <a-tooltip :title="$t('object_tracing.warning')">
                  <a-button type="ghost" class="mr-5 text-ems-gray500" @click="onWarning([record.id])">
                    <template #icon>
                      <img
                        class="w-20px h-20px m-auto"
                        src="../../../static/img/icon/WarningIconGray.svg"
                        alt="WarningIconGray"
                      />
                    </template>
                  </a-button>
                </a-tooltip>
              </div> -->
              <ActionButton
                :buttons="buttonActions"
                :item="record"
                @onDelete="onDelete(record)"
                @onWarning="onWarning([record.id])"
                @onChangeStatus="onChangeStatus(record)"
                @onView="onView(record)"
              />
            </div>
          </template>

          <template v-if="column.key === 'status' && record">
            <a-checkbox
              v-model:checked="record.traceStatus"
              @change="onChangeStatus(record)"
            ></a-checkbox>
          </template>
          <template v-if="column.key === 'id' && record">
            <!--            <span v-if="record.name">{{ record.name }}</span>-->
            <!--            <template v-else-if="record.id.length > 20 || record.name.length > 20">-->
            <!--              <a-tooltip :title="record.name ? record.name : record.id">-->
            <!--                <span>{{ record.name ? record.name.substring(0, 20) + '...' : record.id.substring(0, 20) + '...' }}</span>-->
            <!--              </a-tooltip>-->
            <!--            </template>-->
            <!--            <span v-else>{{ record.id }}</span>-->
            <div @click="onView(record)" class="cursor-pointer">
              <template v-if="record.name">
                <div class="text-ems-main4 hover:underline">
                  <a-tooltip
                    placement="bottom"
                    overlayClassName="tooltip-view-more"
                    :title="
                      t('common.view-details', {
                        name: t('object_information.tracing'),
                      })
                    "
                  >
                    <span>{{ record.name }}</span>
                  </a-tooltip>
                </div>
              </template>
              <template v-else>
                <div class="text-ems-main4">
                  <a-tooltip
                    v-if="record.id.length > 10"
                    placement="bottom"
                    overlayClassName="tooltip-view-more"
                    :title="
                      t('common.view-details', {
                        name: t('object_information.tracing'),
                      })
                    "
                  >
                    <span>{{ record.id.substring(0, 10) + '...' }}</span>
                  </a-tooltip>
                  <a-tooltip
                    v-else
                    placement="bottom"
                    overlayClassName="tooltip-view-more"
                    :title="
                      t('common.view-details', {
                        name: t('object_information.tracing'),
                      })
                    "
                  >
                    <span>{{ record.id }}</span>
                  </a-tooltip>
                </div>
              </template>
            </div>
          </template>
          <template v-if="column.key === 'imagesAvatar' && record">
            <div class="w-[62px] h-[62px] img-custom">
              <img
                :src="
                  record.imagesAvatar
                    ? record.imagesAvatar
                    : require('@/static/img/no-img.png')
                "
                :alt="record.name"
                class="w-full h-full object-cover cursor-pointer"
              />
            </div>
          </template>
          <template v-if="column.key === 'ioGroups' && record">
            <div
              class="flex items-center flex-wrap gap-y-1"
              v-if="record.ioGroups"
            >
              <div
                class="tag flex-shrink-0"
                v-for="(item, index) in record.ioGroups"
                :key="index"
              >
                <span
                  class="inline-block rounded-lg py-6px px-10px text-center mr-2 cursor-pointer text-ems-gray100"
                  v-if="index < 2"
                  @click="viewDetailGroup(item)"
                  :class="getColor(listGroupDetail[item].warningLevel)"
                >
                  {{
                    listGroupDetail[item] && listGroupDetail[item].name > 20
                      ? `${listGroupDetail[item].name.substring(0, 20)}...`
                      : listGroupDetail[item].name
                  }}
                </span>
              </div>
              <a-popover
                placement="top"
                overlay-class-name="popup-groups"
                v-if="record.ioGroups.length > 2"
              >
                <template #content>
                  <div>
                    <span
                      class="tag-custom inline-block rounded-lg py-[6px] text-ems-gray100 text-sm px-3 uppercase mr-2 mb-2 cursor-pointer"
                      v-for="(item, idx) in record.ioGroups"
                      :key="idx"
                      @click="viewDetailGroup(item)"
                      :class="getColor(listGroupDetail[item].warningLevel)"
                    >
                      {{
                        listGroupDetail[item] && listGroupDetail[item].name > 20
                          ? `${listGroupDetail[item].name.substring(0, 20)}...`
                          : listGroupDetail[item].name
                      }}
                    </span>
                  </div>
                </template>
                <span
                  class="inline-block rounded-lg p-1 px-2 bg-ems-tag1 text-ems-gray200"
                >
                  ...
                </span>
              </a-popover>
            </div>
          </template>
        </template>
      </cds-table>
    </cds-cards>

    <div
      class="absolute top-[53%] right-0 z-60 icon-expand-drawer cursor-pointer"
      @click="openDrawer = true"
      v-if="isSave"
    />
  </cds-main>
  <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
// import ConstantAPI from '@/config/ConstantAPI';
import { useStore } from 'vuex';
//import ClipButton from '@/components/buttons/ClipButton.vue';
import { useI18n } from 'vue-i18n';
import ActionButton from '@/components/action-button';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import InputSearch from '@/components/input-search/index.vue';
import { OBJECT_TYPE_TEXT, OBJECT_TYPE } from '@/util/common-constant';
import { getText, trim } from '@/util/common-utils';
import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'lodash';
import ConstantAPI from '../../../config/ConstantAPI';
import { notification } from 'ant-design-vue';
import { TAB_KEY, defaultButton } from '../../../util/common-constant';
import SelectSearchList from '@/components/select-search-list/SelectSearchList.vue';
import UploadSearchList from '@/components/upload-search-list/UploadSearchList.vue';
import { WARNING_LEVEL } from '@/config/Constant';
import { FORM_MODE } from '@/util/common-constant';

const showTb = ref(false);
const page = ref(1);
const size = ref(10);
const listGroupByUser = computed(() => state.ioGroup.listGroupByUser);
const listGroupDetail = computed(() => {
  let groups = {};
  state.ioGroup.listGroup.map((el) => {
    groups[el.id] = el;
  });
  return groups;
});
onMounted(() => {
  dispatch('setTitleHeader', t('object_tracing.header'));
  if (route.query.page) {
    page.value = parseInt(route.query.page);
  }
  if (route.query.size) {
    size.value = parseInt(route.query.size);
  }
  formState.name = route.query.searchName ? route.query.searchName : null;
  formState.type = route.query.searchType
    ? Number(route.query.searchType)
    : OBJECT_TYPE.VEHICLE;
  if (route.query.searchGroupId) {
    formState.groupId =
      typeof route.query.searchGroupId === 'string'
        ? [Number(route.query.searchGroupId)]
        : route.query.searchGroupId.map((item) => Number(item));
  } else {
    formState.groupId = [];
  }
  formState.imgUrl = route.query.searchImage ? route.query.searchImage : null;
  dispatch('ioGroup/getAllGroup');
  dispatch('ioGroup/getAllGroupByUser');
  showTb.value = true;
});
const apiFetch = ConstantAPI.OBJECT_MANAGEMENT.GET_BY_CONDITION;
const { t } = useI18n();
const DEBOUNCE_TIME = 300;
const route = useRoute();
const router = useRouter();
const onChangePage = () => {
  router.push({
    query: {
      ...route.query,
      page: table.value.page,
      size: table.value.size,
    },
  });
};
const getColor = computed(() => (lv) => {
  let color = WARNING_LEVEL[lv - 1].color;
  return color;
});
const columns = ref([
  {
    title: t('object_tracing.id'),
    dataIndex: 'id',
    key: 'id',
    width: 300,
  },
  {
    title: t('object_tracing.img'),
    dataIndex: 'imagesAvatar',
    key: 'imagesAvatar',
    width: 200,
  },
  {
    title: t('object_tracing.type'),
    dataIndex: 'type',
    key: 'type',
    width: 200,
    customRender({ record }) {
      return t(getText(record.type, OBJECT_TYPE_TEXT));
    },
  },
  {
    title: t('object_tracing.ioGroups'),
    dataIndex: 'ioGroups',
    key: 'ioGroups',
    width: 330,
  },
  {
    title: t('object_tracing.get_alerts'),
    dataIndex: 'status',
    key: 'status',
    width: 200,
    align: 'center',
  },
  {
    title: t('common.action'),
    dataIndex: 'action',
    key: 'action',
    width: 200,
    align: 'center',
    fixed: 'right',
    customCell: () => ({
      onClick: (e) => e.stopPropagation(),
    }),
  },
]);
const { buttonsForm, buttonActions } = defaultButton('tracing');
const { state, dispatch } = useStore();
const openDrawer = ref(false);
const isSave = ref(false);
const table = ref(null);
let formState = reactive({
  name: null,
  type: null,
  groupId: [],
  imgUrl: '',
  tracing: true,
});
const onSearch = () => {
  table.value.fetchData().then(async () => {
    if (table.value.page > Math.ceil(table.value.totalPages)) {
      page.value = 1;
      table.value.page = 1;
      await table.value.fetchData(false);
    }
    await router.push({
      query: {
        ...route.query,
        page: table.value.page,
        size: table.value.size,
        searchName: trim(formState.name),
        searchType: formState.type,
        searchGroupId: formState.groupId,
        searchImage: formState.imgUrl,
      },
    });
  });
};
const onDelaySearch = debounce(() => {
  onSearch();
}, DEBOUNCE_TIME);
const onView = (row) => {
  router.push({
    path: '/trace-management/object-tracing/detail',
    query: {
      id: row.id,
      tab: TAB_KEY.INFO,
      page: table.value.page,
      type: row.type,
      ...route.query,
    },
  });
};
const viewDetailGroup = (item) => {
  router.push({
    path: '/io-management/group-management',
    query: { idGroup: item, page: table.value.page },
  });
};
const showConfirmCustom = ref(null);
const onDelete = async (row) => {
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    dispatch('tracingObject/deleteTracing', {
      ids: [row.id],
      type: row.type,
    }).then(() => {
      onSearch();
    });
  }
};
const onDeleteList = async () => {
  const listId = table.value.selectedRows.map((item) => item.id);
  if (listId.length < 1) {
    notification.error({
      message: t('common.notification'),
      description: t('common.no_record_selected'),
      duration: 4,
    });
    return;
  }
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    const response = await dispatch('tracingObject/deleteTracing', listId);
    if (response) {
      onSearch();
    }
  }
};
const resetForm = () => {
  formState.name = null;
  formState.type = null;
  formState.groupId = [];
  formState.imgUrl = null;
  formState.tracing = true;
};

const refresh = () => {
  resetForm();
  table.value.resetAndFetch(true);
  router.push({
    query: {},
  });
};

const onChangeStatus = (record) => {
  dispatch('tracingObject/changeStatus', {
    id: record.id,
    status: record.traceStatus,
    type: Number(record.type),
  }).then((response) => {
    if (response) {
      setTimeout(() => {
        onSearch();
      }, 300);
    }
  });
};

const onWarningMulti = () => {
  const listId = table.value.selectedRows.map((item) => item.id);
  if (listId.length < 1) {
    notification.error({
      message: t('common.notification'),
      description: t('common.no_record_selected'),
      duration: 4,
    });
    return;
  }
  dispatch('alertConfiguration/changeAction', FORM_MODE.CREATE);
  router.push({
    path: '/alert-system/alert-configuration',
    query: { id: null, mode: FORM_MODE.CREATE, listProfile: listId },
  });
};
const onWarning = (profiles) => {
  dispatch('alertConfiguration/changeAction', FORM_MODE.CREATE);
  router.push({
    path: '/alert-system/alert-configuration',
    query: { id: null, mode: FORM_MODE.CREATE, listProfile: profiles },
  });
};
</script>

<style lang="scss">
.list-ident-event .img-custom img {
  border-radius: 8px;
}
.list-ident-event .icon-expand-drawer {
  background-image: url('../../../static/img/icon/open-drawer.svg');
  background-size: cover;
  height: 67px;
  width: 19px;
}
.list-ident-event .icon-expand-drawer:hover {
  background-image: url('../../../static/img/icon/open-drawer-active.svg');
}
.list-ident-event .tag:hover {
  transition: 0.2s;
  transform: scale(1.1);
}
.popup-groups .ant-popover-content {
  box-shadow: 0 0px 20px 0 rgba(255, 255, 255, 0.14) !important;
}
.popup-groups .ant-popover-content .ant-popover-arrow-content {
  background: #1f1f1f !important;
}
.popup-groups .ant-popover-content .ant-popover-inner {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 17, 29, var(--tw-bg-opacity));
  border-radius: 4px;
}
.action-button-custom .ant-btn {
  border: none;
}
</style>
