<template>
  <cds-main class="user-management h-full flex flex-col">
    <a-form
      @keydown.enter.prevent=""
      ref="formRef"
      :model="formState"
      :label-col="{ span: 0 }"
      :wrapper-col="wrapperCol"
      label-align="left"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
    >
      <div
        class="pl-[16px] pr-[20px] py-[14px] grid grid-cols-12 justify-between items-center"
      >
        <div class="col-span-2">
          <input-search
            class="w-100 ant-input-sm"
            v-model:value="formState.username"
            :maxlength="50"
            size="small"
            :placeholder="$t('common.search')"
            @onSearchAdvanced="onSearchAdvanced"
          >
          </input-search>
        </div>
        <cds-search-form-button
          class="!pb-0 col-span-10 justify-end"
          :buttons="buttonsForm"
          @onClear="refresh"
          @onCreate="preCreate"
          @onDelete="onDelete"
        />
      </div>
    </a-form>
    <cds-cards title="" class="h-full full-height-card m-0">
      <cds-table
        ref="table"
        :has-checkbox="true"
        :index-column="false"
        :pagination="true"
        @onRowClicked="
          (record) => {
            hasPermEdit(perms, 'user') ? onEdit(record) : onView(record);
          }
        "
        :columns="columns"
        :api-fetch="apiFetch"
        :param-fetch="paramFetch"
        @handleSelectCheckbox="handleSelectCheckbox"
      >
        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'status' && record">
            <div class="flex justify-start items-start">
              <tag
                tag-type="colorful"
                :color="record.status === 1 ? '#006400' : '#5B5B9F'"
                :text="
                  record.status
                    ? t('role-group.active')
                    : t('role-group.inactive')
                "
              ></tag>
            </div>
          </template>
          <template v-if="column.key === 'rolesName' && record">
            <a-popover placement="top" overlay-class-name="popup-table-user">
              <template #content>
                <p class="text-ems-gray200">
                  {{ record.rolesName }}
                </p>
              </template>
              <p class="max-w-[150px] truncate">{{ record.rolesName }}</p>
            </a-popover>
          </template>
          <template v-if="column.key === 'username' && record">
            <p
              class="text-ems-main4 cursor-pointer"
              @click="
                hasPermEdit(perms, 'user') ? onEdit(record) : onView(record)
              "
            >
              {{ record.username }}
            </p>
          </template>
          <template v-if="column.key === 'action' && record">
            <div class="flex justify-center">
              <ActionButton
                :buttons="buttonActions"
                @onEdit="onEdit(record)"
                @onView="onView(record)"
                @onDelete="onDeleteRecord(record)"
              />
            </div>
          </template>
        </template>
      </cds-table>
    </cds-cards>
  </cds-main>

  <cds-modal
    :title="$t(titleModal)"
    :visible="visible"
    :width="1095"
    :mask-closable="false"
    hide-footer
    @onCancel="visible = false"
  >
    <form-modal ref="modal" @saveSuccess="saveSuccess"></form-modal>
  </cds-modal>

  <cds-modal
    :visible="isPopupDelete"
    :mask-closable="false"
    hide-footer
    @onCancel="isPopupDelete = false"
    class="bg-ems-gray800 pb-0"
  >
    <ModalConfirm
      ref="modal"
      @confirm="handleConfirm"
      @onClose="isPopupDelete = false"
      :title="t('common.confirm_delete')"
    ></ModalConfirm>
  </cds-modal>
</template>

<script src="./user-controller.js"></script>

<style lang="scss">
.user-management .ant-tag {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
</style>
