<template>
  <cds-main class="list-menu">
    <div
      class="pl-[16px] pr-[20px] py-[14px] grid grid-cols-12 justify-between items-center gap-20px"
    >
      <div class="col-span-2">
        <InputSearch
          v-model:value="formState.menuName"
          size="small"
          :maxlength="50"
          :placeholder="$t('common.search')"
          @press-enter="onSearch"
        />
      </div>
      <div class="col-span-2">
        <select-custom
          :options="PHAN_HE"
          :label-prop="'label'"
          :value-prop="'value'"
          v-model:value="formState.clientId"
          :placeholder="t('menu.phan-he')"
          class="mr-4"
          :is-show-icon-searched="false"
          @select="onSearch"
        />
      </div>
      <cds-search-form-button
        :buttons="buttonsForm"
        @onClear="resetForm"
        @onCreateMenu="preCreateMenu"
        @onCreateButton="preCreateButton"
        @onDelete="onDelete"
        class="!p-0 col-span-8 justify-end"
      />
    </div>
    <drawer-custom
      :key="drawerKey"
      :visible="openDrawer"
      :width="450"
      @onClose="onClose"
      @onCancel="onCancel"
      :component="component"
      :title="title"
      :parent="parent"
      @onSearchAdvanced="onSearchAdvanced"
      @saveSuccesss="handleSaveSuccess"
      :is-show-hide-btn="false"
    />
    <cds-cards class="!h-[calc(100vh-60px-32px-76px-52px)] full-height-card">
      <cds-table
        ref="table"
        :has-checkbox="true"
        :index-column="false"
        @onRowClicked="
          (record) => {
            hasPermEdit(perms, 'menu') ? onEdit(record) : onView(record);
          }
        "
        :columns="columns"
        :api-fetch="apiFetch"
        :param-fetch="paramFetch"
        :check-strictly="false"
      >
        <template v-slot:[`icon`]="record">
          <cds-feather-icons :type="record.icon" />
        </template>

        <template #custom-body="{ column, record }">
          <template v-if="column.key === 'icon'">
            <span>
              <InlineSvg
                v-if="record.icon.startsWith('ems')"
                :src="`/assets/ems/${record.icon}.svg`"
                :alt="record.icon"
                height="16"
                width="16"
                class="fill-current text-ems-white"
              />
              <cds-feather-icons
                v-else
                :type="record.icon"
                size="14"
                class="text-ems-white"
              />
            </span>
          </template>
          <template v-if="column.key === 'name' && record">
            <p
              class="text-ems-main4 cursor-pointer"
              @click="
                hasPermEdit(perms, 'menu') ? onEdit(record) : onView(record)
              "
            >
              {{ record.name }}
            </p>
          </template>
          <template v-if="column.key === 'type'">
            <a-tag
              class="px-3"
              :color="typeMenu(record.type).color"
              :text="typeMenu(record.type).text"
            >
              {{ typeMenu(record.type).text }}
            </a-tag>
          </template>

          <template v-if="column.key === 'status'">
            <a-tag
              class="px-3"
              :color="record.status === 1 ? '#21A603' : '#E33148'"
              :text="record.status === 0 ? $t('menu.an') : $t('menu.hien')"
            >
              {{ record.status === 0 ? $t('menu.an') : $t('menu.hien') }}
            </a-tag>
          </template>
          <template v-if="column.key === 'clientId'">
            <span>{{
              record.clientId === 1
                ? $t('menu.phan-he-1')
                : $t('menu.phan-he-2')
            }}</span>
          </template>

          <template v-if="column.key === 'action' && record">
            <div class="flex justify-center">
              <action-button
                class="text-left"
                :buttons="buttonActions"
                @onEdit="onEdit(record)"
                @onView="onView(record)"
              />
            </div>
          </template>
        </template>
      </cds-table>
    </cds-cards>
    <cds-modal
      :visible="isPopupDelete"
      :mask-closable="false"
      hide-footer
      @onCancel="isPopupDelete = false"
      class="bg-ems-gray800 pb-0"
    >
      <ModalConfirm
        ref="modal"
        @confirm="handleConfirm"
        @onClose="isPopupDelete = false"
        :title="t('common.confirm_delete')"
      ></ModalConfirm>
    </cds-modal>
  </cds-main>
</template>

<script src="./menu-controller.js" />

<style>
.list-menu .ant-select {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-radius: 8px;
  height: 38px;
  width: 100%;
}
.list-menu .ant-select .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.list-menu .ant-select .ant-select-selector .ant-select-selection-search {
  background-color: transparent !important;
}
.list-menu .ant-select .ant-select-selector input {
  height: 24px !important;
}
.list-menu .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
  box-shadow: none !important;
}
.list-menu input.ant-input {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
.list-menu .ant-tag {
  line-height: 30px !important;
  min-width: max-content !important;
}
.list-menu .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  background: #2B2A3A !important;
  border-color: #2B2A3A !important;
}
</style>
