<template>
  <div class="bg-ems-gray1000 min-h-100vh detail-ident-evt">
    <div
      class="flex justify-between items-end gap-[16px] bg-ems-gray800 pl-[18px] pr-[30px] py-[18px] border-b-1 border-solid border-ems-tag1 w-full"
    >
      <div class="flex items-center gap-20px max-w-1/2">
        <span class="font-semibold text-white"
          >{{ t('identification-event.info') }}:</span
        >
        <span
          :title="data.name ? data.name : data.id"
          class="text-ems-main2 text-16px font-semibold max-w-full truncate cursor-pointer"
          @click="copyContent(data.name ? data.name : data.id)"
        >
          {{ data.name ? data.name : data.id }}
        </span>
      </div>
      <div class="flex gap-[16px]">
        <ClipButton
          v-if="data.tracing"
          class="flex items-center gap-[8px]"
          :type="'primary'"
          :disabled="isLoading"
          @click="viewTracingObject"
        >
          <img
            src="@/static/img/icon/camera-trace.png"
            alt="Icon camera"
            width="22"
            height="22"
          />
          <span>{{ t('identification-event.trace-info') }}</span>
        </ClipButton>
        <!--        <ClipButton-->
        <!--          v-if="data.id"-->
        <!--          class="flex items-center gap-[6px]"-->
        <!--          :type="'primary'"-->
        <!--          :disabled="isLoading"-->
        <!--          @click="onUpdateInfoIO"-->
        <!--        >-->
        <!--          <cds-feather-icons type="plus" size="18" />-->
        <!--          <span>{{ t('identification-event.update-info') }}</span>-->
        <!--        </ClipButton>-->
      </div>
    </div>
    <div class="p-16px grid grid-cols-10 gap-16px">
      <div class="2xl:col-span-7 col-span-6 pb-16px">
        <div class="relative bg-ems-gray800 pt-[18px] px-[20px] pb-[24px]">
          <div
            class="text-ems-gray200 text-18px mb-24px font-semibold uppercase"
          >
            {{ t('identification-event.image') }}
          </div>
          <div
            class="w-full h-418px relative grid grid-cols-12 gap-15px image-event-custom"
          >
            <div class="w-full col-span-9 row-span-1">
              <div class="w-full h-418px relative group" v-if="data.imageUrl">
                <Image
                  :src="data.imageUrl"
                  :alt="data.imageUrl"
                  class="block w-full !h-full rounded-8px"
                >
                  <template #previewMask>
                    <cds-feather-icons
                      class="cursor-pointer"
                      color="#FFFFFF"
                      type="eye"
                      size="16"
                    />
                  </template>
                </Image>
              </div>
              <div
                v-else
                class="bg-ems-gray700 h-418px rounded-8px flex flex-col justify-center"
              >
                <img
                  src="../../../static/img/icon/ImageIcon.svg"
                  alt="Icon Upload"
                  class="block mx-auto"
                />
                <div class="text-center text-ems-gray300">
                  {{ t('object_information.no_image') }}
                </div>
              </div>
            </div>
            <div class="col-span-3 h-418px grid grid-rows-2 gap-20px">
              <div class="relative group" v-if="data.imageCropUrl">
                <Image
                  :src="data.imageCropUrl"
                  :alt="data.imageCropUrl"
                  class="block w-full !h-full rounded-8px object-cover"
                >
                  <template #previewMask>
                    <cds-feather-icons
                      class="cursor-pointer"
                      color="#FFFFFF"
                      type="eye"
                      size="16"
                    />
                  </template>
                </Image>
              </div>
              <div class="relative group" v-if="data.imageLicensePlateUrl">
                <Image
                  :src="data.imageLicensePlateUrl"
                  :alt="data.imageLicensePlateUrl"
                  class="block w-full !h-full rounded-8px"
                >
                  <template #previewMask>
                    <cds-feather-icons
                      class="cursor-pointer"
                      color="#FFFFFF"
                      type="eye"
                      size="16"
                    />
                  </template>
                </Image>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-[16px] bg-ems-gray800 p-[20px]">
          <carousel
            ref="myCarousel"
            :items-to-show="4.5"
            :items-to-scroll="4"
            :snap-align="'start'"
          >
            <slide v-for="slide in listIdentify" :key="slide">
              <div
                class="relative group p-16px bg-ems-tag1 text-ems-white rounded-8px border-1px hover:bg-ems-gray600 cursor-pointer w-full h-full"
                :class="
                  currentEventId === slide.eventId
                    ? 'border-ems-main2'
                    : 'border-transparent'
                "
                @click="showDetailSlide(slide)"
              >
                <div class="mb-8px">
                  <img
                    :src="slide.imageCropUrl"
                    :alt="slide.imageCropUrl"
                    class="block mx-auto w-full h-96px rounded-4px object-cover"
                  />
                </div>
                <div
                  class="w-full text-12px text-left leading-19px font-semibold flex gap-5px justify-between"
                >
                  <div
                    class="w-full truncate"
                    :title="slide.name ? slide.name : slide.id"
                  >
                    {{ slide.name ? slide.name : slide.id }}
                  </div>
                  <span
                    v-if="data.status === EVENT_STATUS_VALUE.CHECKIN"
                    class="inline-block w-[fit-content] border-solid rounded-4px leading-19px whitespace-nowrap text-right"
                    :class="'text-ems-boTro500 border-ems-boTro500'"
                    >{{ t('identification-event.checkin') }}</span
                  >
                  <span
                    v-if="data.status === EVENT_STATUS_VALUE.CHECKOUT"
                    class="inline-block w-[fit-content] border-solid rounded-4px leading-19px whitespace-nowrap text-right"
                    :class="'text-ems-boTro4_600 border-ems-boTro4_600'"
                    >{{ t('identification-event.checkout') }}</span
                  >
                  <span
                    v-if="data.status === EVENT_STATUS_VALUE.APPEAR"
                    class="inline-block w-[fit-content] border-solid rounded-4px leading-19px whitespace-nowrap text-right"
                    :class="'text-ems-boTro2_600 border-ems-boTro2_600'"
                    >{{ t('identification-event.appear') }}</span
                  >
                  <span
                    v-if="data.status === EVENT_STATUS_VALUE.INTRUSION"
                    class="inline-block w-[fit-content] border-solid rounded-4px leading-19px whitespace-nowrap text-right"
                    :class="'text-ems-main1 border-ems-main1'"
                    >{{ t('identification-event.intrusion') }}</span
                  >
                </div>
                <div class="text-12px text-left leading-19px font-semibold">
                  {{
                    slide.createdAtLocalDate
                      ? formatDate(
                          slide.createdAtLocalDate,
                          'HH:mm:ss - DD/MM/YYYY'
                        )
                      : ''
                  }}
                </div>
                <div
                  class="text-11px text-left leading-19px font-semibold text-ellipsis line-clamp-2"
                  :title="slide.location"
                >
                  {{ slide.location ? slide.location : '' }}
                </div>
                <!--                <div-->
                <!--                  class="bg-ems-main2 absolute right-20px top-88px rounded-3px hidden group-hover:block"-->
                <!--                  @click="onRouteToHistory(slide)"-->
                <!--                >-->
                <!--                  <div class="text-ems-white text-10px px-7px py-5px">-->
                <!--                    {{ t('common.view_detail') }}-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </slide>
          </carousel>
          <div class="flex gap-18px justify-center">
            <div
              class="h-30px w-30px rounded-[50%] bg-ems-gray400 flex items-center justify-center"
              :class="
                disabledPrev ? 'opacity-70' : 'cursor-pointer opacity-100'
              "
              @click="onPrevSlide"
            >
              <img
                src="../../../static/img/icon/LeftArrowBlack.svg"
                alt="icon-up"
                height="14"
                width="8"
                class="w-8px h-14px"
              />
            </div>
            <div
              class="h-30px w-30px rounded-[50%] bg-ems-gray400 flex items-center justify-center"
              :class="
                disabledNext ? 'opacity-70' : 'cursor-pointer opacity-100'
              "
              @click="onNextSlide"
            >
              <img
                src="../../../static/img/icon/RightArrowBlack.svg"
                alt="icon-up"
                height="14"
                width="8"
                class="w-8px h-14px"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="2xl:col-span-3 col-span-4" v-if="!isLoading">
        <div class="relative bg-ems-gray800 pt-[18px] px-[20px] pb-[24px]">
          <div class="text-ems-gray200 text-lg mb-10px font-semibold uppercase">
            {{ t('identification-event.ident_info') }}
          </div>
          <div class="grid grid-cols-2 gap-x-20px gap-y-10px">
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.object_type')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ t(getText(data.type, OBJECT_TYPE_TEXT_VALUE)) }}</span
              >
            </div>
            <div
              v-if="data.type === TYPE_TEXT.VEHICLE"
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.license_plate')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-main4 hover:underline cursor-pointer"
                >{{ data.detail ? data.detail.licensePlate : data.ioId }}</span
              >
            </div>
            <div
              v-else
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.object_name')
              }}</span>
              <!--              <span-->
              <!--                class="block mt-2 min-h-[25px] text-base text-ems-main1 hover:text-ems-main1 hover:underline cursor-pointer"-->
              <!--                >{{ data.name ? data.name : data.id }}</span-->
              <!--              >-->
              <span class="block mt-2 min-h-[25px] text-base text-ems-main1">{{
                data.name ? data.name : data.profileUUID
              }}</span>
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.time-ident')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  formatDate(data.createdAtLocalDate, 'HH:mm:ss - DD/MM/YYYY')
                }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.status')
              }}</span>
              <span
                v-if="data.status === EVENT_STATUS_VALUE.CHECKIN"
                class="px-[10px] py-[2px] inline-block border-[1px] w-[fit-content] border-solid rounded-4px"
                :class="'text-ems-boTro500 border-ems-boTro500'"
                >{{ t('identification-event.checkin') }}</span
              >
              <span
                v-if="data.status === EVENT_STATUS_VALUE.CHECKOUT"
                class="px-[10px] py-[2px] inline-block border-[1px] w-[fit-content] border-solid rounded-4px"
                :class="'text-ems-boTro4_600 border-ems-boTro4_600'"
                >{{ t('identification-event.checkout') }}</span
              >
              <span
                v-if="data.status === EVENT_STATUS_VALUE.APPEAR"
                class="px-[10px] py-[2px] inline-block border-[1px] w-[fit-content] border-solid rounded-4px"
                :class="'text-ems-boTro2_600 border-ems-boTro2_600'"
                >{{ t('identification-event.appear') }}</span
              >
              <span
                v-if="data.status === EVENT_STATUS_VALUE.INTRUSION"
                class="px-[10px] py-[2px] inline-block border-[1px] w-[fit-content] border-solid rounded-4px"
                :class="'text-ems-main1 border-ems-main1'"
                >{{ t('identification-event.intrusion') }}</span
              >
            </div>
            <div class="col-span-2 flex flex-col justify-between">
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.location')
              }}</span>
              <!--              <a-->
              <!--                class="block mt-2 min-h-[25px] text-base text-ems-gray300 hover:text-ems-main2 hover:underline cursor-pointer"-->
              <!--                :href="`/trace-management/identification-event?searchCamera=${data.cameraId}`"-->
              <!--                target="_blank"-->
              <!--                >{{-->
              <!--                  data.cameraName && data.location-->
              <!--                    ? data.cameraName + ' - ' + data.location-->
              <!--                    : ''-->
              <!--                }}</a-->
              <!--              >-->
              <div class="block mt-2 min-h-[25px] text-base text-ems-gray300">
                {{
                  data.cameraName && data.location
                    ? data.cameraName + ' - ' + data.location
                    : ''
                }}
              </div>
            </div>
          </div>
          <div class="w-full h-1px bg-ems-gray700 mt-[6px] mb-[20px]"></div>
          <div class="text-ems-gray200 text-lg mb-10px font-semibold uppercase">
            {{ t('identification-event.detail') }}
          </div>
          <div
            class="grid grid-cols-2 gap-x-20px gap-y-10px"
            v-if="data.type === TYPE_TEXT.HUMAN"
          >
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.age')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ data.humanInfo ? data.humanInfo.face.age : '' }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.gender')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.humanInfo && data.humanInfo.face.gender
                    ? t(GENDER[data.humanInfo.face.gender])
                    : ''
                }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.chungToc')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.humanInfo && data.humanInfo.face.race
                    ? t(RACE[data.humanInfo.face.race])
                    : ''
                }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.kieuToc')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.humanInfo && data.humanInfo.person.hair
                    ? t(HAIR[data.humanInfo.person.hair])
                    : ''
                }}</span
              >
            </div>

            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.kieuAo')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ data.humanInfo ? kieuAo : '' }}</span
              >
            </div>

            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.kieuQuan')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ data.humanInfo ? kieuQuan : '' }}</span
              >
            </div>

            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('identification-event.kieuTui')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.humanInfo && data.humanInfo.bag
                    ? t(BAG[data.humanInfo.bag])
                    : ''
                }}</span
              >
            </div>
            <div class="flex gap-[20px] self-end">
              <a-checkbox class="text-ems-gray500" v-model:checked="mu" disabled
                >{{ t('identification-event.mu') }}
              </a-checkbox>
              <a-checkbox
                class="text-ems-gray500"
                disabled
                v-model:checked="kinhMat"
                >{{ t('identification-event.kinhMat') }}
              </a-checkbox>
            </div>
          </div>
          <div
            v-if="data.type === TYPE_TEXT.VEHICLE"
            class="grid grid-cols-2 gap-x-20px gap-y-10px"
          >
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.vehicle_owner')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.vehicleInfo ? data.vehicleInfo.vehicleOwner : ''
                }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.vehicle_type')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ data.vehicleInfo ? getNameVehicleType : '' }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.vehicle_brand')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{
                  data.vehicleInfo ? data.vehicleInfo.vehicleBrand : ''
                }}</span
              >
            </div>
            <div
              class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
            >
              <span class="text-sm text-ems-gray500">{{
                t('object_information.color')
              }}</span>
              <span
                class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                >{{ vehicleColor }}</span
              >
            </div>
          </div>
          <div
            class="text-ems-gray200 text-18px mb-10px font-semibold mt-[20px] uppercase"
          >
            {{ t('object_information.group_belong') }}
          </div>
          <div class="col-span-2 flex flex-wrap gap-16px">
            <div
              v-if="listGroupDetail && listGroupDetail.length < 1"
              class="text-ems-main2"
            >
              {{ t('object_information.not_belong_any_group') }}
            </div>
            <div
              v-else
              v-for="item in listGroupDetail"
              :key="item"
              class="group bg-ems-main1 py-6px px-16px rounded-8px text-ems-white text-16px leading-25px relative cursor-pointer"
              :class="classGroupTag(item)"
              @click="viewGroup(item)"
            >
              {{
                item.name > 20 ? `${item.name.substring(0, 20)}...` : item.name
              }}
            </div>
          </div>
        </div>

        <!--        Hồ sơ ảnh tương đồng-->
        <!--        <div class="mt-16px p-16px bg-ems-gray800">-->
        <!--          <div-->
        <!--            class="text-ems-gray200 text-18px mb-24px font-semibold uppercase"-->
        <!--          >-->
        <!--            {{ t('identification-event.io-same-img') }}-->
        <!--          </div>-->
        <!--          <cds-input-->
        <!--            class="w-100 ant-input-sm"-->
        <!--            v-model:value="formState.name"-->
        <!--            :show-count="false"-->
        <!--            size="default"-->
        <!--            :placeholder="-->
        <!--              t('identity_object.form_label.search_by_info_placeholder')-->
        <!--            "-->
        <!--          />-->
        <!--          <p class="text-ems-gray200 text-18px my-3">-->
        <!--            {{ t('common.suggest') }}-->
        <!--          </p>-->
        <!--          <Empty-->
        <!--            v-if="listSimilar.length === 0"-->
        <!--            :description="t('common.no_data_displayed')"-->
        <!--          />-->
        <!--          <div v-else class="flex flex-col gap-[20px]">-->
        <!--            <div-->
        <!--              v-for="item in listSimilar"-->
        <!--              :key="item.id"-->
        <!--              class="flex gap-[20px] p-[20px] bg-ems-tag1 rounded-lg relative"-->
        <!--            >-->
        <!--              <img-->
        <!--                :src="-->
        <!--                  !item.imagesAvatar-->
        <!--                    ? require('@/static/img/no-img.png')-->
        <!--                    : item.imagesAvatar-->
        <!--                "-->
        <!--                :alt="item.imagesAvatar"-->
        <!--                width="120"-->
        <!--                height="120"-->
        <!--                class="rounded-lg w-[120px] h-[120px] flex-shrink-0 object-cover"-->
        <!--              />-->
        <!--              <div class="flex flex-col gap-12px">-->
        <!--                <span class="text-lg text-ems-gray100 font-semi-bold">{{-->
        <!--                  item.name-->
        <!--                }}</span>-->
        <!--                <span-->
        <!--                  v-if="item.location"-->
        <!--                  class="bg-[#EA621F] text-base px-10px py-3px block w-[fit-content] rounded text-ems-gray100"-->
        <!--                  >{{ item.location }}</span-->
        <!--                >-->
        <!--                <span class="text-base text-ems-boTro4_600">{{-->
        <!--                  item.percent-->
        <!--                }}</span>-->
        <!--              </div>-->
        <!--              <span-->
        <!--                class="px-[16px] py-[3px] text-[12px] text-ems-gray300 border-1px border-solid border-ems-gray300 block absolute bottom-[13px] right-[16px] rounded-full cursor-pointer"-->
        <!--                @click="viewDetailIO(item)"-->
        <!--                >{{ t('common.detail') }}</span-->
        <!--              >-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import {
  MARKER_TYPE,
  TAB_KEY,
  TYPE_MESSAGE,
  PEER_TYPE,
  EVENT_STATUS_VALUE,
  OBJECT_TYPE_TEXT_VALUE,
  TYPE_EVENT,
  TYPE_TEXT,
} from '@/util/common-constant';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { WARNING_LEVEL } from '@/config/Constant';
import { formatDate, getText } from '@/util/common-utils';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import { BAG, GENDER, HAIR, RACE } from '../../../config/Constant';
import { Image } from 'ant-design-vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import { decryptData } from '@/dataService/dataService';
import { cloneDeep, debounce } from 'lodash';
import dayjs from 'dayjs';
import { copyContent } from '../../../util/common-utils';

const idConnect = computed(() => state.coordinator.idConnected);
let microphoneStream = null;
let audioContext = null;
// let analyser = null;
const isMicrophoneOn = ref(false);
const volumeArray = ref(Array(12).fill(0));
const minutes = ref(0);
const seconds = ref(0);
const secondsCopy = ref(0);
let mediaRecorder;
let recordedChunks = [];
let intervalId;
const userIdSelect = ref('');
// const Voice = defineAsyncComponent(() => import('./components/voice.vue'));
var relativeTime = require('dayjs/plugin/relativeTime');
const { t } = useI18n();
const router = useRouter();
const idTrinhsat = ref('');
const { state, dispatch } = useStore();
// const formState = reactive({
//   name: '',
// });

const classGroupTag = (item) => {
  return {
    'bg-ems-main1': item.warningLevel === WARNING_LEVEL[0].id,
    'bg-ems-boTro2_600': item.warningLevel === WARNING_LEVEL[1].id,
    'bg-ems-boTro4_800': item.warningLevel === WARNING_LEVEL[2].id,
    'bg-ems-gray500': item.warningLevel === WARNING_LEVEL[3].id,
  };
};
// const listGroupKeyValue = computed(() => state.ioGroup.listGroupKeyValue);
const data = ref({
  name: '',
});
const mu = computed(
  () => data.value.humanInfo && data.value.humanInfo.person.hat
);
const kinhMat = computed(
  () => data.value.humanInfo && data.value.humanInfo.person.glasses
);
// const listSimilar = computed(() => state.identificationEvent.listSimilar);
const showMap = ref(false);
const connection = ref(null);
const connectionChat = ref(null);
const myCarousel = ref(null);
const listIdentify = ref([]);
const onPrevSlide = () => {
  myCarousel.value.prev();
  disabledPrev.value =
    myCarousel.value.data.currentSlide.value ===
    myCarousel.value.data.minSlide.value;
  disabledNext.value =
    myCarousel.value.data.currentSlide.value ===
    myCarousel.value.data.maxSlide.value;
};
const onNextSlide = () => {
  myCarousel.value.next();
  disabledNext.value =
    myCarousel.value.data.currentSlide.value ===
    myCarousel.value.data.maxSlide.value;
  disabledPrev.value =
    myCarousel.value.data.currentSlide.value ===
    myCarousel.value.data.minSlide.value;
};
const disabledPrev = ref(true);
const disabledNext = ref(true);
// const onRouteToHistory = (record) => {
//   const routeLink = router.resolve({
//     name: '/io-management/object-management/edit',
//     query: {
//       id: record.id,
//       type: OBJECT_TYPE[record.type],
//       mode: FORM_MODE.UPDATE,
//       tab: 'history',
//     },
//   });
//   window.open(routeLink.href, `window${record.id}`, "popup");
// };
const currentEventId = ref(null);
const showDetailSlide = (record) => {
  currentEventId.value = record.eventId;
  data.value = { ...record };
  onClickEvent(record);
};
const onClickEvent = (evt) => {
  if (listProfileEvent.value[evt.profileUUID]) {
    for (let mk of listProfileEvent.value[evt.profileUUID].markers) {
      if (mk.eventId === evt.eventId) {
        mk.detailInfo = mk.infoWindow;
        mk.showInfo = true;
      } else {
        mk.showInfo = false;
        delete mk.detailInfo;
      }
    }
  }
  mapCommon.value.setMarker(listProfileEvent.value);
  mapCommon.value.moveToLocation(evt.latitude, evt.longitude);
};
onMounted(async () => {
  await dispatch('deviceManagement/getStatusAllDevice');
  new ResizeObserver(resizeMap).observe(mapContainer.value);
  isLoading.value = true;
  // WebSocket
  connection.value = new WebSocket(
    `wss://${decryptData(window.VUE_APP_IP)}/socket?access_token=${
      state.auth.tokenInfo.accessToken
    }&id=${state.auth.userInfo.id}`
  );
  connectionChat.value = new WebSocket(
    `wss://${decryptData(
      window.VUE_APP_IP
    )}/chat-service/ws_chat?access_token=${
      state.auth.tokenInfo.accessToken
    }&id=${state.auth.userInfo.id}`
  );
  connectionChat.value.onmessage = async (event) => {
    let data;

    try {
      data = JSON.parse(event.data);
    } catch (e) {
      console.error('Error parsing WebSocket message data:', e);
      return;
    }
    let typeMessage = data.updateType;
    switch (typeMessage) {
      case TYPE_MESSAGE.UPDATE_NEW_MESS:
        {
          const { senderId, receiverId } = data.content;
          if (
            senderId === idTrinhsat.value &&
            receiverId === state.auth.userInfo.id
          ) {
            dispatch('coordinator/updateMessage', data.content);
          } else if (idTrinhsat.value === receiverId) {
            dispatch('coordinator/updateMessage', data.content);
          }
        }
        break;
      case TYPE_MESSAGE.DELETE_MESS:
        {
          const { dialogId, dialogType } = data.content.message;
          listenUpdateNewMessage(dialogId, dialogType);
        }
        break;
      case TYPE_MESSAGE.UPDATE_NEW_PHONE_ROOM:
        {
          const { roomId, receiverType, receiverId } = data.content;
          localStorage.setItem('receiverType', receiverType);
          localStorage.setItem('roomId', roomId);
          dispatch('coordinator/handleGetRoomID', roomId);
          dispatch('coordinator/handleChangeStatus', receiverId);
          dispatch('coordinator/joinRoom', {
            roomId: roomId,
          });
          dispatch('coordinator/joinChannel', {
            appId: decryptData(window.VUE_APP_API_AGORA_ID),
            channel: roomId,
            token: null,
            uuid: null,
          });
        }
        break;
      case TYPE_MESSAGE.UPDATE_LEAVE_ROOM:
        {
          const { roomId, isDeleteRoom } = data.content;
          const receiverType = localStorage.getItem('receiverType');
          let value = {
            roomId: roomId,
          };
          if (Number(receiverType) === PEER_TYPE.USER) {
            leaveAndCleanUp(value);
          } else {
            if (isDeleteRoom) {
              leaveAndCleanUp(value);
            }
          }
        }
        break;
      default:
        break;
    }
  };
  connection.value.onmessage = (event) => {
    mappingData(JSON.parse(event.data));
    getMarker(JSON.parse(event.data));
  };
  connection.value.ondisconnect = async () => {
    await dispatch('getUserInfo');
    connection.value = new WebSocket(
      `wss://${decryptData(window.VUE_APP_IP)}/socket?access_token=${
        state.auth.tokenInfo.accessToken
      }&id=${state.auth.userInfo.id}`
    );
  };
  getLocationTrinhSat();
  // End WebSocket
  dispatch('setTitleHeader', t('identification-event.title-view'));
  await Promise.all([
    dispatch('ioGroup/getAllGroup'),
    await dispatch('identificationEvent/getListSimilar', {
      id: data.value.id,
      keyword: null,
    }),
    dispatch('listSelect/getAllColor'),
    dispatch('listSelect/getAllVehicleType'),
  ]);
  showMap.value = true;
  isLoading.value = false;
});
const listenUpdateNewMessage = async (dialogId, dialogType) => {
  dispatch('coordinator/getMessagesByDialogs', {
    dialogId,
    dialogType,
    isChated: true,
  });
};
const leaveAndCleanUp = (value) => {
  dispatch('coordinator/leaveRoom', value).then(() => {
    dispatch('coordinator/handleChangeStatus', null);
    dispatch('coordinator/leaveChannel');
    localStorage.removeItem('receiverType');
    localStorage.removeItem('roomId');
  });
};

onUnmounted(() => {
  if (connection.value) {
    connection.value.close();
  }
  dispatch('ioManagement/resetForm');
});
const mapCommon = ref(null);
const resizeMap = debounce(() => {
  if (mapCommon.value) mapCommon.value.resize();
}, 0);
const mapContainer = ref();
const isLoading = ref(false);
const vehicleType = computed(() => state.listSelect.vehicleType);
const getNameVehicleType = computed(() => {
  const index = vehicleType.value.findIndex(
    (item) => item.value === data.value.vehicleType
  );
  if (index !== -1) {
    return vehicleType.value[index].name;
  }
  return '';
});
const listGroup = computed(() => state.ioGroup.listGroup);
const listGroupDetail = computed(() => {
  let list = [];
  if (data.value.ioGroups && listGroup.value) {
    data.value.ioGroups.forEach((item) => {
      const index = listGroup.value.findIndex((group) => group.id === item);
      if (index !== -1) {
        list.push(listGroup.value[index]);
      }
    });
  }
  return list;
});
const listProfileEvent = ref({});
const color = computed(() => state.listSelect.color);
const vehicleColor = computed(() => {
  const index = color.value.findIndex(
    (item) => item.value === data.value.color
  );
  if (index !== -1) {
    return color.value[index].name;
  }
  return '';
});
const mappingData = (response) => {
  if (response.type === TYPE_EVENT.WARNING) {
    data.value.type = response.data.type;
    data.value.id = response.data.id;
    data.value.eventId = response.data.id;
    currentEventId.value = response.data.id;
    data.value.createdAtLocalDate = response.data.createdAtLocalDate;
    data.value.status = response.data.directionType;
    data.value.imageUrl = response.data.imageUrl;
    data.value.imageCropUrl = response.data.imageCropUrl;
    data.value.imageLicensePlateUrl = response.data.imageLicensePlateUrl;
    data.value.latitude = response.data.cameraLatitude;
    data.value.longitude = response.data.cameraLongitude;
    data.value.cameraName = response.data.cameraName;
    data.value.location = response.data.cameraLocation;
    // data.value.characteristic = response.data.characteristic;
    data.value.ioGroups = response.data.ioGroups;
    data.value.cameraId = response.data.cameraId;
    data.value.profileUUID = response.data.profileUUID;
    // const metaData = JSON.parse(response.data.event.data.metaData);
    if (data.value.type === 'VEHICLE') {
      // data.value.bienSo = response.data.io.licensePlate;
      // data.value.classify = response.data.io.brandId;
      // data.value.color = response.data.io.vehicleColor;
      // data.value.owner = response.data.io.ownerVehicle;
      // data.value.vehicleType = response.data.io.vehicleType;
    }
    if (data.value.type === 'HUMAN') {
      data.value.name = response.data.name;
    }
    const identify = cloneDeep(data.value);
    listIdentify.value.unshift(identify);

    disabledNext.value =
      myCarousel.value.data.currentSlide.value ===
      myCarousel.value.data.maxSlide.value;
    disabledPrev.value =
      myCarousel.value.data.currentSlide.value ===
      myCarousel.value.data.minSlide.value;
  }
};

const getMarker = (lastEvt) => {
  if (lastEvt.type === TYPE_EVENT.WARNING) {
    let profileUUID = lastEvt.data.profileUUID;
    let evt = {
      latitude: lastEvt.data.cameraLatitude,
      longitude: lastEvt.data.cameraLongitude,
      eventId: lastEvt.data.id,
      imageCropUrl: lastEvt.data.imageCropUrl,
      createdAtLocalDate: lastEvt.data.createdAtLocalDate,
      cameraName: lastEvt.data.cameraName,
      location: lastEvt.data.cameraLocation,
      name: lastEvt.data.name,
    };

    let item = {
      lat: evt.latitude,
      lng: evt.longitude,
      eventId: evt.eventId,
      id: evt.eventId,
      profileUUID: profileUUID,
      showInfo: true,
      infoWindow: `
          <div class="content-wrapper p-16px">
            <img
              src="${
                evt.imageCropUrl
                  ? evt.imageCropUrl
                  : require('@/static/img/no-img.png')
              }"
              width="120"
              height="120"
              class="rounded-lg w-[120px] h-[120px] flex-shrink-0 object-cover"
            />
            <div class="flex flex-col justify-center gap-[12px]">
              <span class="text-base text-ems-white font-semibold whitespace-nowrap">${
                evt.name ? evt.name : 'Unknown'
              }</span>
              <ul class="list-disc ml-20px flex flex-col gap-1 w-[300px]">
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.time-appear'
                )}  ${formatDate(
        evt.createdAtLocalDate,
        'DD/MM/YYYY - HH:mm:ss'
      )}  </li>
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.camera'
                )}   ${evt.cameraName} </li>
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.vi-tri'
                )}   ${evt.location} </li>
              </ul>
            </div>
          </div>
    `,
    };
    item.detailInfo = item.infoWindow;
    for (let i in listProfileEvent.value) {
      for (let mk of listProfileEvent.value[i].markers) {
        mk.showInfo = false;
        delete mk.detailInfo;
      }
    }
    const markersCount =
      (listProfileEvent.value[profileUUID] &&
        listProfileEvent.value[profileUUID].markers.length) ||
      0;
    let color;
    if (markersCount === 0) {
      color = 'blue';
    } else if (markersCount === 1) {
      color = 'red';
    } else {
      color =
        '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0');
    }
    if (
      Object.keys(listProfileEvent.value).length === 0 ||
      !listProfileEvent.value[profileUUID]
    ) {
      listProfileEvent.value[profileUUID] = {
        profileUUID: profileUUID,
        name: lastEvt.data.name,
        color: color,
        markers: [item],
        path: [[item.lng, item.lat]],
      };
    } else if (listProfileEvent.value[profileUUID]) {
      listProfileEvent.value[profileUUID].markers.push(item);
      listProfileEvent.value[profileUUID].path.push([item.lng, item.lat]);
    }
    mapCommon.value.setMarker(listProfileEvent.value);
    mapCommon.value.moveToLocation(item.lat, item.lng);
    mapCommon.value.updateLineString(
      listProfileEvent.value[profileUUID].path,
      profileUUID,
      listProfileEvent.value[profileUUID].color
    );
    if (mapFullScreen.value) {
      mapFullScreen.value.setMarker(listProfileEvent.value);
      mapFullScreen.value.moveToLocation(item.lat, item.lng);
      mapFullScreen.value.updateLineString(
        listProfileEvent.value[profileUUID].path,
        profileUUID,
        listProfileEvent.value[profileUUID].color
      );
    }
  }
};
const viewGroup = (item) => {
  router.push({
    path: '/io-management/group-management',
    query: { idGroup: item },
  });
};

// const viewDetailIO = (item) => {
//   console.log(item);
// };
// const onUpdateInfoIO = () => {
//   const routeLink = router.resolve({
//     name: '/io-management/object-management/edit',
//     query: {
//       id: data.value.id,
//       type: OBJECT_TYPE[data.value.type],
//       mode: FORM_MODE.UPDATE,
//     },
//   });
//   window.open(routeLink.href, 'new');
// }

const viewTracingObject = async () => {
  await dispatch('ioManagement/tracingObject', data.value.id);
  router.push({
    path: '/trace-management/object-tracing/detail',
    query: { id: data.value.id, tab: TAB_KEY.INFO },
  });
};
const mapFullScreen = ref();
const markersTrinhSat = ref([]);
const listDeviceStatus = computed(
  () => state.deviceManagement.listDeviceStatus || []
);
const getLocationTrinhSat = () => {
  let arr = [];
  dayjs.extend(relativeTime);

  listDeviceStatus.value.map((e) => {
    let data = {
      lat: e.latitude,
      lng: e.longitude,
      type: MARKER_TYPE.TRINH_SAT,
      isUser: true,
      id: e.userId,
      detailInfo: `<div class="content-wrapper" id="maker-goong-ts-${e.userId}">
            <div class="relative">
              <img
                src="${
                  e.avatar ? e.avatar : require('@/static/img/no-img.png')
                }"
                width="64"
                height="64"
                class="rounded-full w-[64px] h-[64px] flex-shrink-0 object-cover"
              />
            </div>
            <div class="flex flex-col justify-center gap-[12px]">
              <span class="text-base text-ems-white font-semibold whitespace-nowrap">ĐC. ${
                e.userName ? e.userName : e.userId
              }</span>
              <div class="font-semibold text-ems-gray200 w-[300px]">${
                e.location
              }</div>
              ${
                e.lastOnline
                  ? `<li class="text-[12px] font-semibold text-ems-gray500">${dayjs(
                      e.lastOnline
                    ).fromNow()}</li>`
                  : ''
              }
              <div class="flex gap-[16px] justify-end items-center">
                <div id="ptt-mic-${
                  e.userId
                }"  class="h-[36px] w-[36px] hover:bg-ems-gray700 rounded-full bg-ems-gray800 flex-shrink-0 flex justify-center items-center select-none">
                  <img
                    id="mic-img-${e.userId}"
                    src="/assets/ems/mic-open.svg"
                    width="14"
                    height="19"
                    class="flex-shrink-0 object-cover select-none cursor-pointer"
                  />
                </div>
                <div id="ptt-chat-${
                  e.userId
                }" class="h-[36px] w-[36px] hover:bg-ems-gray700 rounded-full bg-ems-gray800 flex-shrink-0 flex justify-center items-center">
                  <img
                    src="/assets/ems/chat.svg"
                    width="14"
                    height="19"
                    class="flex-shrink-0 object-cover"
                  />
                </div>
              </div>
            </div>
          </div>`,
    };
    arr.push(data);
  });
  markersTrinhSat.value = arr;
};
watch(idConnect, (newValue) => {
  userIdSelect.value = {
    ...userIdSelect.value,
    idUser: newValue,
  };
});
// const startTime = () => {
//   if (intervalId) return;

//   intervalId = setInterval(() => {
//     if (seconds.value === 59) {
//       seconds.value = 0;
//       secondsCopy.value = 0;
//       minutes.value += 1;
//     } else {
//       seconds.value += 1;
//       secondsCopy.value += 1;
//     }
//   }, 1000);
// };
const stopTime = () => {
  clearInterval(intervalId);
  intervalId = null;
  seconds.value = 0;
  minutes.value = 0;
  volumeArray.value = Array(12).fill(null);
};

// const startMicrophone = (id) => {
//   idTrinhsat.value = id;
//   if (idConnect.value === id) {
//     dispatch('coordinator/startTalking');
//     startTime();
//     isMicrophoneOn.value = true;
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         audioContext = new AudioContext();
//         microphoneStream = stream;
//         const source = audioContext.createMediaStreamSource(stream);
//         analyser = audioContext.createAnalyser();
//         source.connect(analyser);
//         analyser.fftSize = 256;
//         analyzeVolume();
//         mediaRecorder = new MediaRecorder(stream);
//         mediaRecorder.ondataavailable = (event) => {
//           if (event.data.size > 0) {
//             recordedChunks.push(event.data);
//           }
//         };
//         mediaRecorder.start();
//       })
//       .catch((error) => {
//         console.error('Error accessing microphone:', error);
//       });
//   }

//   // emit('startRecording');
// };

const stopMicrophone = () => {
  if (idConnect.value === idTrinhsat.value) {
    dispatch('coordinator/stopTalking');
    isMicrophoneOn.value = false;
    stopTime();
    if (audioContext) {
      audioContext.close();
      audioContext = null;
      // analyser = null;
      microphoneStream.getTracks().forEach((track) => track.stop());
    }
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      isMicrophoneOn.value = false;
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(recordedChunks, { type: 'audio/webm' });
        const audioFile = new File([audioBlob], 'recording.webm', {
          type: 'audio/webm',
        });
        recordedChunks = [];
        customRequest(audioFile);
        if (microphoneStream) {
          microphoneStream.getTracks().forEach((track) => track.stop());
        }
        if (audioContext) {
          audioContext.close();
        }
      };
    }
  }

  // emit('stopTalking');
};
// const analyzeVolume = () => {
//   const dataArray = new Uint8Array(analyser.frequencyBinCount);
//   const analyze = () => {
//     if (!isMicrophoneOn.value) return;
//     setTimeout(() => {
//       analyser.getByteFrequencyData(dataArray);
//       const averageVolume =
//         dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
//       volumeArray.value.push(Math.floor(averageVolume));
//       if (volumeArray.value.length > 12) {
//         volumeArray.value.shift();
//       }
//       analyze();
//     }, 200);
//   };
//   analyze();
// };
const handleMouseUp = () => {
  stopMicrophone();
};

onMounted(() => {
  window.addEventListener('mouseup', handleMouseUp);
});

onUnmounted(() => {
  window.removeEventListener('mouseup', handleMouseUp);
  stopMicrophone();
});
const handleSendMessages = (url) => {
  let mess = {
    receiverId: idTrinhsat.value,
    receiverType: 1,
    messageType: 'file',
    content: '',
    mediaType: `audio`,
    length: secondsCopy.value,
    url: url,
  };
  dispatch('coordinator/sendMessages', mess);
};

const customRequest = async (file) => {
  if (secondsCopy.value > 0) {
    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    fmData.append('file', file);
    fmData.append('rootPath', 'image');
    try {
      const response = await DataService.upload(
        ConstantAPI.upload_service.UPLOAD,
        fmData,
        config
      );
      checkResponse(response, () => {
        handleSendMessages(response.data.url);
        secondsCopy.value = 0;
      });
    } catch (err) {
      console.log(err);
    }
  }
};
</script>
<style>

.image-event-custom {
  .ant-image {
    height: 100%;
    width: 100%;
  }

  .ant-image-mask {
    border-radius: 8px;
  }
}

img.ant-image-preview-img {
  display: inline-block;
}

.full-modal {
  .ant-modal-body {
    padding: 0 !important;

    .ant-spin-nested-loading,
    .ant-spin-container {
      height: 100%;
    }
  }

  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }

  .ant-modal-body {
    flex: 1;
  }
}

.carousel__slide {
  padding: 0 8px;
}

.carousel__slide:last-child {
  padding: 0 0 0 8px;
}

.carousel__slide:first-child {
  padding: 0 8px 0 0;
}

</style>
