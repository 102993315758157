<template>
  <div class="bg-ems-gray1000 detail-ident-evt">
    <div class="px-16px grid grid-cols-10 gap-16px">
      <div
        ref="mapContainer"
        class="mt-[16px] bg-ems-gray800 p-[20px] col-span-7"
      >
        <div class="flex justify-between items-center mb-17px">
          <span class="uppercase text-base font-semibold text-ems-gray200">{{
            t('identification-event.location')
          }}</span>
          <img
            src="/assets/ems/maximize.svg"
            width="24"
            height="24"
            alt=""
            class="cursor-pointer"
            @click="fullScreenMap"
          />
        </div>
        <div class="relative">
          <div class="absolute z-1 top-[20px] left-[8px]">
            <a-popover placement="right" overlay-class-name="popup-groups">
              <template #content>
                <div class="text-ems-white font-semi-bold">
                  {{ t('object_tracing.position-user') }}
                </div>
              </template>
              <img
                :src="
                  showTrinhSat
                    ? require('@/static/img/icon/trinhsat-active.png')
                    : require('@/static/img/icon/trinhsat.png')
                "
                alt="trinhsat"
                width="43"
                height="43"
                class="cursor-pointer !mb-5"
                @click="onClickShowTS"
              />
            </a-popover>
          </div>
          <GoongLineMap
            ref="mapCommon"
            v-if="showMap"
            :markers="listProfileEvent"
            :center="center"
            :marker-trinh-sat="markersTrinhSat"
            @click-marker-ts="clickMkTs"
          />
        </div>
      </div>
      <!-- chat voice -->
      <div v-if="component" class="h-[98%] mt-16px bg-ems-gray800 col-span-3">
        <Suspense>
          <component
            key-class="default"
            :is="component"
            :id-trinhsat="idTrinhsat"
            :max-height="600"
          />
        </Suspense>
      </div>
    </div>
  </div>
  <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
  <cds-modal
    :visible="showMapFullScreen"
    :mask-closable="false"
    hide-footer
    @onCancel="showMapFullScreen = false"
    width="100%"
    :closable="closable"
    wrap-class-name="full-modal"
  >
    <div class="relative">
      <div class="absolute z-1 top-[20px] left-[8px]">
        <a-popover placement="right" overlay-class-name="popup-groups">
          <template #content>
            <div class="text-ems-white font-semi-bold">
              {{ t('object_tracing.position-user') }}
            </div>
          </template>
          <img
            :src="
              showTrinhSatFull
                ? require('@/static/img/icon/trinhsat-active.png')
                : require('@/static/img/icon/trinhsat.png')
            "
            alt="trinhsat"
            width="43"
            height="43"
            class="cursor-pointer !mb-5"
            @click="onClickShowTSFull"
          />
        </a-popover>
      </div>
      <div
        v-if="component"
        class="w-[400px] h-[500px] bg-ems-gray800 absolute z-999 bottom-[0px] right-[0px]"
      >
        <Suspense>
          <component
            key-class="fullMap"
            :is="component"
            :id-trinhsat="idTrinhsat"
            :max-height="300"
          />
        </Suspense>
      </div>
      <GoongMap
        ref="mapFullScreen"
        :markers="listProfileEvent"
        :center="center"
        :id-map="'full-screen-map'"
        class="h-full"
        :marker-trinh-sat="markersTrinhSat"
        @click-marker-ts="clickMkTs"
      />
    </div>
  </cds-modal>
</template>
<script setup>
import {
  computed,
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import {
  MARKER_TYPE,
  TYPE_MESSAGE,
  PEER_TYPE,
  TYPE_EVENT,
} from '@/util/common-constant';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { formatDate } from '@/util/common-utils';
import GoongMap from './components/TraceMapFull.vue';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import GoongLineMap from './components/TraceMap.vue';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import 'vue3-carousel/dist/carousel.css';
import { decryptData } from '@/dataService/dataService';
import { cloneDeep, debounce } from 'lodash';
import dayjs from 'dayjs';

const idConnect = computed(() => state.coordinator.idConnected);
let microphoneStream = null;
let audioContext = null;
// let analyser = null;
const isMicrophoneOn = ref(false);
const volumeArray = ref(Array(12).fill(0));
const minutes = ref(0);
const seconds = ref(0);
const secondsCopy = ref(0);
let mediaRecorder;
let recordedChunks = [];
let intervalId;
const userIdSelect = ref('');
// const Voice = defineAsyncComponent(() => import('./components/voice.vue'));
const Chat = defineAsyncComponent(() => import('./components/chat.vue'));
const Voice = defineAsyncComponent(() =>
  import('./components/tab-walkie-talkie.vue')
);
var relativeTime = require('dayjs/plugin/relativeTime');
const { t } = useI18n();
const idTrinhsat = ref('');
const idDialog = ref('');
const isType = ref('');
const { state, dispatch } = useStore();
const component = computed(() => state.coordinator.component);
// const formState = reactive({
//   name: '',
// });

// const listGroupKeyValue = computed(() => state.ioGroup.listGroupKeyValue);
const data = ref({
  name: '',
});

// const listSimilar = computed(() => state.identificationEvent.listSimilar);
const center = ref({ lat: 21.051448321171982, lng: 105.80020101183814 });
const showMap = ref(false);
const connection = ref(null);
const connectionChat = ref(null);
const myCarousel = ref(null);
const listIdentify = ref([]);

const disabledPrev = ref(true);
const disabledNext = ref(true);
// const onRouteToHistory = (record) => {
//   const routeLink = router.resolve({
//     name: '/io-management/object-management/edit',
//     query: {
//       id: record.id,
//       type: OBJECT_TYPE[record.type],
//       mode: FORM_MODE.UPDATE,
//       tab: 'history',
//     },
//   });
//   window.open(routeLink.href, `window${record.id}`, "popup");
// };
const currentEventId = ref(null);
onMounted(async () => {
  await dispatch('deviceManagement/getStatusAllDevice');
  new ResizeObserver(resizeMap).observe(mapContainer.value);
  isLoading.value = true;
  // WebSocket
  connection.value = new WebSocket(
    `wss://${decryptData(window.VUE_APP_IP)}/socket?access_token=${
      state.auth.tokenInfo.accessToken
    }&id=${state.auth.userInfo.id}`
  );
  connectionChat.value = new WebSocket(
    `wss://${decryptData(
      window.VUE_APP_IP
    )}/chat-service/ws_chat?access_token=${
      state.auth.tokenInfo.accessToken
    }&id=${state.auth.userInfo.id}`
  );
  connectionChat.value.onmessage = async (event) => {
    let data;

    try {
      data = JSON.parse(event.data);
    } catch (e) {
      console.error('Error parsing WebSocket message data:', e);
      return;
    }
    let typeMessage = data.updateType;
    switch (typeMessage) {
      case TYPE_MESSAGE.UPDATE_NEW_MESS:
        {
          const { senderId, receiverId } = data.content;
          if (
            senderId === idTrinhsat.value &&
            receiverId === state.auth.userInfo.id
          ) {
            dispatch('coordinator/updateMessage', data.content);
          } else if (idTrinhsat.value === receiverId) {
            dispatch('coordinator/updateMessage', data.content);
          }
        }
        break;
      case TYPE_MESSAGE.DELETE_MESS:
        {
          const { dialogId, dialogType } = data.content.message;
          listenUpdateNewMessage(dialogId, dialogType);
        }
        break;
      case TYPE_MESSAGE.UPDATE_NEW_PHONE_ROOM:
        {
          const { roomId, receiverType, receiverId } = data.content;
          localStorage.setItem('receiverType', receiverType);
          localStorage.setItem('roomId', roomId);
          dispatch('coordinator/handleGetRoomID', roomId);
          dispatch('coordinator/handleChangeStatus', receiverId);
          dispatch('coordinator/joinRoom', {
            roomId: roomId,
          });
          dispatch('coordinator/joinChannel', {
            appId: decryptData(window.VUE_APP_API_AGORA_ID),
            channel: roomId,
            token: null,
            uuid: null,
          });
        }
        break;
      case TYPE_MESSAGE.UPDATE_LEAVE_ROOM:
        {
          const { roomId, isDeleteRoom } = data.content;
          const receiverType = localStorage.getItem('receiverType');
          let value = {
            roomId: roomId,
          };
          if (Number(receiverType) === PEER_TYPE.USER) {
            leaveAndCleanUp(value);
          } else {
            if (isDeleteRoom) {
              leaveAndCleanUp(value);
            }
          }
        }
        break;
      default:
        break;
    }
  };
  connection.value.onmessage = (event) => {
    mappingData(JSON.parse(event.data));
    getMarker(JSON.parse(event.data));
  };
  connection.value.ondisconnect = async () => {
    await dispatch('getUserInfo');
    connection.value = new WebSocket(
      `wss://${decryptData(window.VUE_APP_IP)}/socket?access_token=${
        state.auth.tokenInfo.accessToken
      }&id=${state.auth.userInfo.id}`
    );
  };
  getLocationTrinhSat();
  // End WebSocket
  dispatch('setTitleHeader', t('identification-event.title-location-tracking'));
  await Promise.all([
    dispatch('ioGroup/getAllGroup'),
    await dispatch('identificationEvent/getListSimilar', {
      id: data.value.id,
      keyword: null,
    }),
    dispatch('listSelect/getAllColor'),
    dispatch('listSelect/getAllVehicleType'),
  ]);
  showMap.value = true;
  isLoading.value = false;
});
const listenUpdateNewMessage = async (dialogId, dialogType) => {
  dispatch('coordinator/getMessagesByDialogs', {
    dialogId,
    dialogType,
    isChated: true,
  });
};
const leaveAndCleanUp = (value) => {
  dispatch('coordinator/leaveRoom', value).then(() => {
    dispatch('coordinator/handleChangeStatus', null);
    dispatch('coordinator/leaveChannel');
    localStorage.removeItem('receiverType');
    localStorage.removeItem('roomId');
  });
};

onUnmounted(() => {
  if (connection.value) {
    connection.value.close();
  }
  dispatch('ioManagement/resetForm');
});
const mapCommon = ref(null);
const resizeMap = debounce(() => {
  if (mapCommon.value) mapCommon.value.resize();
}, 0);
const mapContainer = ref();
const isLoading = ref(false);
const listProfileEvent = ref({});
const mappingData = (response) => {
  if (response.type === TYPE_EVENT.WARNING) {
    data.value.type = response.data.type;
    data.value.id = response.data.id;
    data.value.eventId = response.data.id;
    currentEventId.value = response.data.id;
    data.value.createdAtLocalDate = response.data.createdAtLocalDate;
    data.value.status = response.data.directionType;
    data.value.imageUrl = response.data.imageUrl;
    data.value.imageCropUrl = response.data.imageCropUrl;
    data.value.imageLicensePlateUrl = response.data.imageLicensePlateUrl;
    data.value.latitude = response.data.cameraLatitude;
    data.value.longitude = response.data.cameraLongitude;
    data.value.cameraName = response.data.cameraName;
    data.value.location = response.data.cameraLocation;
    // data.value.characteristic = response.data.characteristic;
    data.value.ioGroups = response.data.ioGroups;
    data.value.cameraId = response.data.cameraId;
    data.value.profileUUID = response.data.profileUUID;
    // const metaData = JSON.parse(response.data.event.data.metaData);
    if (data.value.type === 'VEHICLE') {
      // data.value.bienSo = response.data.io.licensePlate;
      // data.value.classify = response.data.io.brandId;
      // data.value.color = response.data.io.vehicleColor;
      // data.value.owner = response.data.io.ownerVehicle;
      // data.value.vehicleType = response.data.io.vehicleType;
    }
    if (data.value.type === 'HUMAN') {
      data.value.name = response.data.name;
    }
    const identify = cloneDeep(data.value);
    listIdentify.value.unshift(identify);

    disabledNext.value =
      myCarousel.value.data.currentSlide.value ===
      myCarousel.value.data.maxSlide.value;
    disabledPrev.value =
      myCarousel.value.data.currentSlide.value ===
      myCarousel.value.data.minSlide.value;
  }
};

const getMarker = (lastEvt) => {
  if (lastEvt.type === TYPE_EVENT.WARNING) {
    let profileUUID = lastEvt.data.profileUUID;
    let evt = {
      latitude: lastEvt.data.cameraLatitude,
      longitude: lastEvt.data.cameraLongitude,
      eventId: lastEvt.data.id,
      imageCropUrl: lastEvt.data.imageCropUrl,
      createdAtLocalDate: lastEvt.data.createdAtLocalDate,
      cameraName: lastEvt.data.cameraName,
      location: lastEvt.data.cameraLocation,
      name: lastEvt.data.name,
    };

    let item = {
      lat: evt.latitude,
      lng: evt.longitude,
      eventId: evt.eventId,
      id: evt.eventId,
      profileUUID: profileUUID,
      showInfo: true,
      infoWindow: `
          <div class="content-wrapper p-16px">
            <img
              src="${
                evt.imageCropUrl
                  ? evt.imageCropUrl
                  : require('@/static/img/no-img.png')
              }"
              width="120"
              height="120"
              class="rounded-lg w-[120px] h-[120px] flex-shrink-0 object-cover"
            />
            <div class="flex flex-col justify-center gap-[12px]">
              <span class="text-base text-ems-white font-semibold whitespace-nowrap">${
                evt.name ? evt.name : 'Unknown'
              }</span>
              <ul class="list-disc ml-20px flex flex-col gap-1 w-[300px]">
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.time-appear'
                )}  ${formatDate(
        evt.createdAtLocalDate,
        'DD/MM/YYYY - HH:mm:ss'
      )}  </li>
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.camera'
                )}   ${evt.cameraName} </li>
                <li class="text-[12px] leading-normal font-semibold text-ems-gray500"> ${t(
                  'object_tracing.vi-tri'
                )}   ${evt.location} </li>
              </ul>
            </div>
          </div>
    `,
    };
    item.detailInfo = item.infoWindow;
    for (let i in listProfileEvent.value) {
      for (let mk of listProfileEvent.value[i].markers) {
        mk.showInfo = false;
        delete mk.detailInfo;
      }
    }
    const markersCount =
      (listProfileEvent.value[profileUUID] &&
        listProfileEvent.value[profileUUID].markers.length) ||
      0;
    let color;
    if (markersCount === 0) {
      color = 'blue';
    } else if (markersCount === 1) {
      color = 'red';
    } else {
      color =
        '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0');
    }
    if (
      Object.keys(listProfileEvent.value).length === 0 ||
      !listProfileEvent.value[profileUUID]
    ) {
      listProfileEvent.value[profileUUID] = {
        profileUUID: profileUUID,
        name: lastEvt.data.name,
        color: color,
        markers: [item],
        path: [[item.lng, item.lat]],
      };
    } else if (listProfileEvent.value[profileUUID]) {
      listProfileEvent.value[profileUUID].markers.push(item);
      listProfileEvent.value[profileUUID].path.push([item.lng, item.lat]);
    }
    mapCommon.value.setMarker(listProfileEvent.value);
    mapCommon.value.moveToLocation(item.lat, item.lng);
    mapCommon.value.updateLineString(
      listProfileEvent.value[profileUUID].path,
      profileUUID,
      listProfileEvent.value[profileUUID].color
    );
    if (mapFullScreen.value) {
      mapFullScreen.value.setMarker(listProfileEvent.value);
      mapFullScreen.value.moveToLocation(item.lat, item.lng);
      mapFullScreen.value.updateLineString(
        listProfileEvent.value[profileUUID].path,
        profileUUID,
        listProfileEvent.value[profileUUID].color
      );
    }
  }
};

// const viewDetailIO = (item) => {
//   console.log(item);
// };
// const onUpdateInfoIO = () => {
//   const routeLink = router.resolve({
//     name: '/io-management/object-management/edit',
//     query: {
//       id: data.value.id,
//       type: OBJECT_TYPE[data.value.type],
//       mode: FORM_MODE.UPDATE,
//     },
//   });
//   window.open(routeLink.href, 'new');
// }

const showMapFullScreen = ref(false);
const mapFullScreen = ref();
const fullScreenMap = () => {
  showMapFullScreen.value = true;
};
const showTrinhSat = ref(true);
const markersTrinhSat = ref([]);
const listDeviceStatus = computed(
  () => state.deviceManagement.listDeviceStatus || []
);
const onClickShowTS = () => {
  showTrinhSat.value = !showTrinhSat.value;
  if (showTrinhSat.value) {
    mapCommon.value.setMarkerTrinhSat(markersTrinhSat.value);
    if (markersTrinhSat.value.length > 0)
      mapCommon.value.moveToLocation(
        markersTrinhSat.value[0].lat,
        markersTrinhSat.value[0].lng
      );
  } else {
    mapCommon.value.clearMarkerTrinhSat();
  }
};
const getLocationTrinhSat = () => {
  let arr = [];
  dayjs.extend(relativeTime);

  listDeviceStatus.value.map((e) => {
    let data = {
      lat: e.latitude,
      lng: e.longitude,
      type: MARKER_TYPE.TRINH_SAT,
      isUser: true,
      id: e.userId,
      detailInfo: `<div class="content-wrapper" id="maker-goong-ts-${e.userId}">
            <div class="relative">
              <img
                src="${
                  e.avatar ? e.avatar : require('@/static/img/no-img.png')
                }"
                width="64"
                height="64"
                class="rounded-full w-[64px] h-[64px] flex-shrink-0 object-cover"
              />
            </div>
            <div class="flex flex-col justify-center gap-[12px]">
              <span class="text-base text-ems-white font-semibold whitespace-nowrap">ĐC. ${
                e.userName ? e.userName : e.userId
              }</span>
              <div class="font-semibold text-ems-gray200 w-[300px]">${
                e.location
              }</div>
              ${
                e.lastOnline
                  ? `<li class="text-[12px] font-semibold text-ems-gray500">${dayjs(
                      e.lastOnline
                    ).fromNow()}</li>`
                  : ''
              }
              <div class="flex gap-[16px] justify-end items-center">
                <div id="ptt-mic-${
                  e.userId
                }"  class="h-[36px] w-[36px] hover:bg-ems-gray700 rounded-full bg-ems-gray800 flex-shrink-0 flex justify-center items-center select-none">
                  <img
                    id="mic-img-${e.userId}"
                    src="/assets/ems/mic-open.svg"
                    width="14"
                    height="19"
                    class="flex-shrink-0 object-cover select-none cursor-pointer"
                  />
                </div>
                <div id="ptt-chat-${
                  e.userId
                }" class="h-[36px] w-[36px] hover:bg-ems-gray700 rounded-full bg-ems-gray800 flex-shrink-0 flex justify-center items-center">
                  <img
                    src="/assets/ems/chat.svg"
                    width="14"
                    height="19"
                    class="flex-shrink-0 object-cover"
                  />
                </div>
              </div>
            </div>
          </div>`,
    };
    arr.push(data);
  });
  markersTrinhSat.value = arr;
};
const showTrinhSatFull = ref(true);
const onClickShowTSFull = () => {
  showTrinhSatFull.value = !showTrinhSatFull.value;
  if (showTrinhSatFull.value) {
    mapFullScreen.value.setMarkerTrinhSat(markersTrinhSat.value);
    if (markersTrinhSat.value.length > 0)
      mapFullScreen.value.moveToLocation(
        markersTrinhSat.value[0].lat,
        markersTrinhSat.value[0].lng
      );
  } else {
    mapFullScreen.value.clearMarkerTrinhSat();
    mapFullScreen.value.moveToLocation(center.value.lat, center.value.lng);
  }
};
const baseUrl = window.location.href;
const clickMkTs = (mk) => {
  setTimeout(() => {
    let property = document.getElementById(`maker-goong-ts-${mk.id}`);
    if (property) {
      let mic = document.getElementById(`ptt-mic-${mk.id}`);
      let micImage = document.getElementById(`mic-img-${mk.id}`);
      if (mic) {
        mic.addEventListener('click', () => {
          let mess = {
            receiverId: mk.id,
            receiverType: PEER_TYPE.USER,
            messageType: 'text',
            content: `${mk.type}`,
            forwarded: false,
          };
          dispatch('coordinator/sendMessages', mess).then(async (data) => {
            let url = `${baseUrl}?trinhSatId=${data.dialogId}&type=voice`;
            isType.value = 'chat';
            dispatch('coordinator/handleSetComponent', Voice);
            idTrinhsat.value = data.dialogId;
            getIdDialog(data.dialogId);
            window.history.pushState(
              {
                path: url,
              },
              '',
              url
            );
          });
        });
        mic.addEventListener('mouseup', () => {
          if (micImage) {
            micImage.src = '/assets/ems/mic-open.svg';
            // stopMicrophone();
          }
        });
        mic.addEventListener('mousedown', () => {
          if (micImage) {
            micImage.src = '/assets/ems/mic.svg';
            // startMicrophone(mk.id);
          }
        });
        mic.addEventListener('mouseleave', () => {
          if (micImage) {
            micImage.src = '/assets/ems/mic-open.svg';
          }
        });
      }
      let chat = document.getElementById(`ptt-chat-${mk.id}`);
      if (chat) {
        chat.addEventListener('click', () => {
          let url = `${baseUrl}?trinhSatId=${mk.id}&type=chat`;
          isType.value = 'chat';
          dispatch('coordinator/handleSetComponent', Chat);
          idTrinhsat.value = mk.id;
          getIdDialog(mk.id);
          window.history.pushState(
            {
              path: url,
            },
            '',
            url
          );
        });
      }
    }
  }, 200);
};

const getIdDialog = (id) => {
  dispatch('coordinator/getDialogsId', id).then((dialogId) => {
    idDialog.value = dialogId;
  });
};
watch(idConnect, (newValue) => {
  userIdSelect.value = {
    ...userIdSelect.value,
    idUser: newValue,
  };
});
// const startTime = () => {
//   if (intervalId) return;

//   intervalId = setInterval(() => {
//     if (seconds.value === 59) {
//       seconds.value = 0;
//       secondsCopy.value = 0;
//       minutes.value += 1;
//     } else {
//       seconds.value += 1;
//       secondsCopy.value += 1;
//     }
//   }, 1000);
// };
const stopTime = () => {
  clearInterval(intervalId);
  intervalId = null;
  seconds.value = 0;
  minutes.value = 0;
  volumeArray.value = Array(12).fill(null);
};

// const startMicrophone = (id) => {
//   idTrinhsat.value = id;
//   if (idConnect.value === id) {
//     dispatch('coordinator/startTalking');
//     startTime();
//     isMicrophoneOn.value = true;
//     navigator.mediaDevices
//       .getUserMedia({ audio: true })
//       .then((stream) => {
//         audioContext = new AudioContext();
//         microphoneStream = stream;
//         const source = audioContext.createMediaStreamSource(stream);
//         analyser = audioContext.createAnalyser();
//         source.connect(analyser);
//         analyser.fftSize = 256;
//         analyzeVolume();
//         mediaRecorder = new MediaRecorder(stream);
//         mediaRecorder.ondataavailable = (event) => {
//           if (event.data.size > 0) {
//             recordedChunks.push(event.data);
//           }
//         };
//         mediaRecorder.start();
//       })
//       .catch((error) => {
//         console.error('Error accessing microphone:', error);
//       });
//   }

//   // emit('startRecording');
// };

const stopMicrophone = () => {
  if (idConnect.value === idTrinhsat.value) {
    dispatch('coordinator/stopTalking');
    isMicrophoneOn.value = false;
    stopTime();
    if (audioContext) {
      audioContext.close();
      audioContext = null;
      // analyser = null;
      microphoneStream.getTracks().forEach((track) => track.stop());
    }
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      isMicrophoneOn.value = false;
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(recordedChunks, { type: 'audio/webm' });
        const audioFile = new File([audioBlob], 'recording.webm', {
          type: 'audio/webm',
        });
        recordedChunks = [];
        customRequest(audioFile);
        if (microphoneStream) {
          microphoneStream.getTracks().forEach((track) => track.stop());
        }
        if (audioContext) {
          audioContext.close();
        }
      };
    }
  }

  // emit('stopTalking');
};
// const analyzeVolume = () => {
//   const dataArray = new Uint8Array(analyser.frequencyBinCount);
//   const analyze = () => {
//     if (!isMicrophoneOn.value) return;
//     setTimeout(() => {
//       analyser.getByteFrequencyData(dataArray);
//       const averageVolume =
//         dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
//       volumeArray.value.push(Math.floor(averageVolume));
//       if (volumeArray.value.length > 12) {
//         volumeArray.value.shift();
//       }
//       analyze();
//     }, 200);
//   };
//   analyze();
// };
const handleMouseUp = () => {
  stopMicrophone();
};

onMounted(() => {
  window.addEventListener('mouseup', handleMouseUp);
});

onUnmounted(() => {
  window.removeEventListener('mouseup', handleMouseUp);
  stopMicrophone();
});
const handleSendMessages = (url) => {
  let mess = {
    receiverId: idTrinhsat.value,
    receiverType: 1,
    messageType: 'file',
    content: '',
    mediaType: `audio`,
    length: secondsCopy.value,
    url: url,
  };
  dispatch('coordinator/sendMessages', mess);
};

const customRequest = async (file) => {
  if (secondsCopy.value > 0) {
    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    fmData.append('file', file);
    fmData.append('rootPath', 'image');
    try {
      const response = await DataService.upload(
        ConstantAPI.upload_service.UPLOAD,
        fmData,
        config
      );
      checkResponse(response, () => {
        handleSendMessages(response.data.url);
        secondsCopy.value = 0;
      });
    } catch (err) {
      console.log(err);
    }
  }
};
</script>
<style>

.image-event-custom {
  .ant-image {
    height: 100%;
    width: 100%;
  }

  .ant-image-mask {
    border-radius: 8px;
  }
}

img.ant-image-preview-img {
  display: inline-block;
}

.full-modal {
  .ant-modal-body {
    padding: 0 !important;

    .ant-spin-nested-loading,
    .ant-spin-container {
      height: 100%;
    }
  }

  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }

  .ant-modal-body {
    flex: 1;
  }
}

.carousel__slide {
  padding: 0 8px;
}

.carousel__slide:last-child {
  padding: 0 0 0 8px;
}

.carousel__slide:first-child {
  padding: 0 8px 0 0;
}

</style>
