<template>
  <div
    ref="formContainer"
    class="form-history h-[calc(100%-50px-65px)] relative overflow-y-auto"
  >
    <div
      class="flex items-center justify-between gap-[20px] px-[16px] py-[8px] bg-ems-gray800"
    >
      <div class="flex items-center gap-[20px]">
        <div class="relative h-[34px]">
          <cds-select
            class="!w-[250px] h-[34px] filter-history"
            :placeholder="t('object_tracing.cam-group')"
            :options="groupCameras"
            v-model:value="formState.groupCameraIds"
            :allow-clear="true"
            :label-prop="'name'"
            :value-prop="'id'"
            @change="changeGroupCamera"
            multiple
            :maxTagCount="1"
            :max-tag-text-length="20"
            :isLabelI18n="false"
          >
          </cds-select>
          <cds-feather-icons
            v-if="formState.groupCameraIds.length === 0"
            type="chevron-down"
            class="text-ems-gray400 absolute right-2 inline-flex items-center h-full h-[34px]"
          />
        </div>
        <div class="relative h-[34px]">
          <cds-select
            class="!w-[250px] h-[34px] filter-history"
            :placeholder="t('object_information.camera')"
            :options="cameras"
            v-model:value="formState.cameraIds"
            :allow-clear="true"
            :label-prop="'name'"
            :value-prop="'id'"
            multiple
            :maxTagCount="1"
            :max-tag-text-length="20"
            :isLabelI18n="false"
          >
          </cds-select>
          <cds-feather-icons
            v-if="formState.cameraIds.length === 0"
            type="chevron-down"
            class="text-ems-gray400 absolute right-2 inline-flex items-center h-full h-[34px]"
          />
        </div>
        <cds-from-to-date
          v-model:dateFrom="formState.dateFrom"
          v-model:dateTo="formState.dateTo"
          :is-show-label="false"
          :allow-clear="true"
          class="from-to-date"
        />
      </div>
      <div class="flex items-center gap-[20px]">
        <ClipButton
          v-if="
            formMode !== FORM_MODE.VIEW &&
            checkHasPermission(perms, EVENT_UNVERIFIED_PERM.VIEW)
          "
          class="flex items-center gap-6px text-sm text-ems-white text-nowrap h-[34px] !px-[30px]"
          :type="'primary'"
          @click="onSuggest"
        >
          <img
            src="../../../static/img/icon/LightIcon.svg"
            alt="img"
            width="56"
            height="56"
            class="w-24px h-24px"
          />
          {{ t('common.suggest') }}
        </ClipButton>
        <ClipButton
          v-if="
            formMode !== FORM_MODE.VIEW &&
            checkHasPermission(perms, EVENT_IDENTIFICATION_PERM.DELETE)
          "
          class="flex items-center text-sm text-ems-white text-nowrap h-[34px] !px-[30px]"
          :type="'primary'"
          :disabled="!showDelete"
          @click="deleteHistory"
        >
          {{ t('common.delete') }}
        </ClipButton>
        <ClipButton
          class="flex items-center text-sm text-ems-white text-nowrap h-[34px] !px-[30px]"
          :type="'secondary'"
          @click="resetData"
        >
          <cds-feather-icons
            type="rotate-cw"
            size="16"
            class="!text-ems-white mr-2"
          />
          {{ t('common.reset') }}
        </ClipButton>
      </div>
    </div>
    <div
      class="mt-[8px] mr-[25px] ml-[16px] bg-ems-gray800 flex flex-col justify-between"
    >
      <div v-if="isLoading" class="spin bg-transparent">
        <a-spin />
      </div>
      <div v-else>
        <div class="grid grid-cols-10 gap-[16px] mt-[8px] px-[20px] pt-[8px]">
          <div class="col-span-7 h-full relative">
            <div
              class="h-full absolute z-3 top-0 left-[26px] flex items-center justify-between"
            >
              <img
                src="/assets/ems/prev.png"
                alt="img"
                width="56"
                height="56"
                :class="
                  selectedIndex === 0 && page === 1
                    ? 'disabled-icon'
                    : 'cursor-pointer hover:opacity-80'
                "
                @click="prevImg()"
              />
            </div>
            <div
              class="absolute z-3 top-0 right-[26px] h-full flex items-center justify-between"
            >
              <img
                src="/assets/ems/next.png"
                alt="img"
                width="56"
                height="56"
                :class="
                  selectedIndex === listImg.length - 1 && page === maxPage
                    ? 'disabled-icon'
                    : 'cursor-pointer hover:opacity-80'
                "
                @click="nextImg()"
              />
            </div>
            <Image
              v-if="item.imageUrl"
              :height="isLargeScreen ? 800 : 500"
              :src="item.imageUrl"
              class="relative !w-full"
              :class="isLargeScreen ? '!h-[800px]' : '!h-[500px]'"
            >
              <template #previewMask>
                <div class="flex gap-20px justify-center items-center h-full">
                  <cds-feather-icons type="eye" size="20" />
                </div>
              </template>
            </Image>
            <img
              v-else
              :src="require('@/static/img/no-img-big.png')"
              alt="img"
              class="rounded-[8px] w-full"
              :class="isLargeScreen ? 'h-[800px]' : 'h-[500px]'"
            />
          </div>
          <div
            class="col-span-3 rounded-[8px] border-[1px] border-solid border-ems-gray500 rounded-lg p-16px overflow-auto hidden-scroll"
            :class="isLargeScreen ? 'h-[800px]' : 'h-[500px]'"
          >
            <Empty
              v-if="!item"
              :description="t('common.no_data_displayed')"
              class="mt-[200px]"
            />
            <div v-else>
              <div
                class="text-ems-gray200 text-lg mb-10px font-semibold uppercase"
              >
                {{ t('identification-event.profile-info') }}
              </div>
              <div
                v-if="item.type === TYPE_TEXT.HUMAN"
                class="grid grid-cols-2 gap-x-20px gap-y-10px"
              >
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.object_name')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-main1"
                    >{{
                      item.detail ? item.detail.name : item.detail.ioId
                    }}</span
                  >
                </div>
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.nationality')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ item.detail ? nationality : '' }}</span
                  >
                </div>
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('identity_object.form.Date of birth')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{
                      item.detail
                        ? formatDate(item.detail.dateOfBirth, 'DD/MM/YYYY')
                        : ''
                    }}</span
                  >
                </div>
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.phone_number')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ item.detail ? item.detail.phone : '' }}</span
                  >
                </div>
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.address')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ item.detail ? item.detail.address : '' }}</span
                  >
                </div>
              </div>
              <div
                v-if="item.type === TYPE_TEXT.VEHICLE"
                class="grid grid-cols-2 gap-x-20px gap-y-10px"
              >
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.license_plate')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-main1"
                    >{{
                      item.detail ? item.detail.licensePlate : item.ioId
                    }}</span
                  >
                </div>
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.vehicle_owner')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ item.detail ? item.detail.ownerVehicle : '' }}</span
                  >
                </div>
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.owner-info')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ item.detail ? item.detail.ownerIdCard : '' }}</span
                  >
                </div>
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.id_vehicle')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ item.detail ? item.detail.registrationNo : '' }}</span
                  >
                </div>
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.address_owner')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ item.detail ? item.detail.ownerAddress : '' }}</span
                  >
                </div>
              </div>
              <div
                class="text-lg text-ems-gray200 font-semibold mt-[20px] mb-[10px]"
              >
                {{ t('identification-event.ident_info') }}
              </div>

              <div class="grid grid-cols-2 gap-x-20px gap-y-10px">
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.time')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{
                      item.createdAt
                        ? formatDate(item.createdAt, 'HH:mm:ss  - DD/MM/YYYY')
                        : ''
                    }}</span
                  >
                </div>
                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.status')
                  }}</span>
                  <span
                    v-if="item.status === EVENT_STATUS_VALUE.CHECKIN"
                    class="text-ems-boTro500 border-ems-boTro500"
                    >{{ t('identification-event.checkin') }}</span
                  >
                  <span
                    v-if="item.status === EVENT_STATUS_VALUE.CHECKOUT"
                    class="text-ems-boTro4_600 border-ems-boTro4_600"
                    >{{ t('identification-event.checkout') }}</span
                  >
                  <span
                    v-if="item.status === EVENT_STATUS_VALUE.APPEAR"
                    class="text-ems-boTro2_600 border-ems-boTro2_600"
                    >{{ t('identification-event.appear') }}</span
                  >
                  <span
                    v-if="item.status === EVENT_STATUS_VALUE.INTRUSION"
                    class="text-ems-main1 border-ems-main1"
                    >{{ t('identification-event.intrusion') }}</span
                  >
                </div>

                <div
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px] col-span-2"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('identification-event.location')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{
                      item.cameraName && item.cameraLocation
                        ? item.cameraName + ' - ' + item.cameraLocation
                        : ''
                    }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.HUMAN"
                  class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('identification-event.age')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ item.humanInfo ? item.humanInfo.face.age : '' }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.HUMAN"
                  class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('identification-event.gender')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{
                      item.humanInfo && item.humanInfo.face.gender
                        ? t(GENDER[item.humanInfo.face.gender])
                        : ''
                    }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.HUMAN"
                  class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('identification-event.chungToc')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{
                      item.humanInfo && item.humanInfo.face.race
                        ? t(RACE[item.humanInfo.face.race])
                        : ''
                    }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.HUMAN"
                  class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('identification-event.kieuToc')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{
                      item.humanInfo && item.humanInfo.person.hair
                        ? t(HAIR[item.humanInfo.person.hair])
                        : ''
                    }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.HUMAN"
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('identification-event.kieuAo')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ kieuAo }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.HUMAN"
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('identification-event.kieuQuan')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ kieuQuan }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.HUMAN"
                  class="border-b-[1px] flex flex-col justify-between border-solid border-ems-gray700 pb-[6px]"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('identification-event.kieuTui')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{
                      item.humanInfo && item.humanInfo.bag
                        ? t(BAG[item.humanInfo.bag])
                        : ''
                    }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.HUMAN"
                  class="flex gap-[20px] self-end flex-wrap"
                >
                  <div class="flex gap-[8px] items-center">
                    <span class="text-sm text-ems-gray500">
                      {{ t('identification-event.mu') }}
                    </span>
                    <a-checkbox
                      class="!text-ems-gray500 m-0"
                      v-model:checked="mu"
                      :disabled="true"
                    ></a-checkbox>
                  </div>
                  <div class="flex gap-[8px] items-center">
                    <span class="text-sm text-ems-gray500">
                      {{ t('identification-event.kinhMat') }}
                    </span>
                    <a-checkbox
                      class="!text-ems-gray500 m-0"
                      v-model:checked="kinhMat"
                      disabled
                    ></a-checkbox>
                  </div>
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.VEHICLE"
                  class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.vehicle_type')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{
                      item.vehicleInfo && item.vehicleInfo.vehicleName
                        ? getNameVehicleType(item.vehicleInfo.vehicleName)
                        : ''
                    }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.VEHICLE"
                  class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.vehicle_brand')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{
                      item.vehicleInfo && item.vehicleInfo.vehicleBrand
                        ? vehicleBrand
                        : ''
                    }}</span
                  >
                </div>
                <div
                  v-if="item.type === TYPE_TEXT.VEHICLE"
                  class="border-b-[1px] border-solid border-ems-gray700 pb-[6px] flex flex-col justify-between"
                >
                  <span class="text-sm text-ems-gray500">{{
                    t('object_information.color')
                  }}</span>
                  <span
                    class="block mt-2 min-h-[25px] text-base text-ems-gray300"
                    >{{ item.vehicleInfo ? vehicleColor : '' }}</span
                  >
                </div>
              </div>
              <div
                class="text-ems-gray200 text-18px mb-10px font-semibold mt-[20px] uppercase"
              >
                {{ t('object_information.group_belong') }}
              </div>
              <div class="col-span-2 flex flex-wrap gap-16px">
                <div
                  v-if="
                    !item.detail ||
                    !item.detail.ioGroups ||
                    item.detail.ioGroups.length < 1
                  "
                  class="text-ems-main2"
                >
                  {{ t('object_information.not_belong_any_group') }}
                </div>
                <div
                  v-else
                  v-for="group in item.detail.ioGroups"
                  :key="group.groupId"
                  class="group tag bg-ems-main1 py-6px px-16px rounded-8px text-ems-white text-16px leading-25px relative cursor-pointer"
                  :class="classGroupTag(group.groupDetail)"
                  @click="viewGroup(group.groupDetail)"
                >
                  {{
                    group.groupDetail.name > 20
                      ? `${group.groupDetail.name.substring(0, 20)}...`
                      : group.groupDetail.name
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Empty
          v-if="!listImg || listImg.length === 0"
          :description="t('common.no_data_displayed')"
          class="w-full !my-[12px]"
        />
        <div
          v-else
          class="flex gap-[6px] mt-[6px] w-full overflow-x-auto pb-1 px-[20px]"
        >
          <div v-for="(img, idx) in listImg" class="relative">
            <img
              :src="
                !img.imageUrl
                  ? require('@/static/img/no-img-big.png')
                  : img.imageUrl
              "
              alt="img"
              width="146"
              height="123"
              class="rounded-[8px] border-[2px] border-solid object-cover cursor-pointer h-[80px] w-[120px]"
              :class="
                img.id === item.id ? 'border-ems-main2' : 'border-transparent'
              "
              @click="selectImg(idx)"
            />
            <a-checkbox
              v-if="formMode != FORM_MODE.VIEW"
              v-model:checked="img.checked"
              class="absolute top-2 right-2 select-img"
            ></a-checkbox>
          </div>
        </div>
      </div>
      <div class="relative w-full pagin-history">
        <span class="absolute right-[120px] text-page-cus">{{
          $t('common.size-changer-text')
        }}</span>
        <a-pagination
          v-model:current="page"
          v-model:page-size="size"
          :total="total"
          @change="onChangePage"
          :page-size-options="pageSizeOptions"
          :show-size-changer="true"
          show-quick-jumper
          @show-size-change="sizeChange"
          :show-total="
            (total) => t('object_information.total-img', { total: total })
          "
        >
        </a-pagination>
      </div>
    </div>

    <cds-modal
      :visible="showConfirm"
      :mask-closable="false"
      hide-footer
      @onCancel="showConfirm = false"
      class="bg-ems-gray800 pb-0"
    >
      <ModalConfirm
        ref="modal"
        @confirm="handleConfirmDelete"
        @onClose="showConfirm = false"
        :title="t('object_information.confirm-delete')"
      ></ModalConfirm>
    </cds-modal>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, reactive, ref, watch, onMounted, onUnmounted } from 'vue';
import Empty from '@/components/empty/index.vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import ModalConfirm from '@/components/modal-confirm/ModalConfirm.vue';
import { Image } from 'ant-design-vue';
import {
  TYPE_TEXT,
  GENDERS,
  NAVIGATION_TYPE,
  TYPE,
  EVENT_STATUS_VALUE,
  FORM_MODE,
  EVENT_IDENTIFICATION_PERM,
  EVENT_UNVERIFIED_PERM,
} from '@/util/common-constant';
import { formatDate, getText, checkHasPermission } from '@/util/common-utils';
import { useRoute, useRouter } from 'vue-router';
import {
  WARNING_LEVEL,
  STYLE,
  COLOR,
  GENDER,
  RACE,
  HAIR,
  BAG,
} from '@/config/Constant';

const { t } = useI18n();
const { state, dispatch } = useStore();
const page = ref(1);
const size = ref(10);
const total = computed(() => state.ioManagement.totalIdenHistory);
const maxPage = computed(() => {
  if (total.value % size.value === 0) {
    return total.value / size.value;
  } else {
    return Math.floor(total.value / size.value) + 1;
  }
});
const formContainer = ref();
const showConfirm = ref(false);
const perms = computed(() => state.auth.userInfo.permissions);
const listImg = computed(() => state.ioManagement.listIdentHistory);
const groupCameras = computed(() => state.tracingObject.listGroupCamera);
const cameras = computed(() => state.tracingObject.listCameraByGroup);
const formMode = computed(() => state.ioManagement.formMode);
const isLoading = ref(false);
const pageSizeOptions = [10, 20, 30, 50];
const router = useRouter();
const showDelete = computed(() => {
  let check = false;
  listImg.value.forEach((e) => {
    if (e.checked) {
      check = true;
    }
  });
  return check;
});
const route = useRoute();
const vehicleType = computed(() => state.listSelect.vehicleType);
const getNameVehicleType = computed(() => (type) => {
  const index = vehicleType.value.findIndex((data) => data.value === type);
  return index !== -1 ? vehicleType.value[index].name : '';
});
const kieuAo = computed(() => {
  let arr = [];
  if (item.value && item.value.humanInfo && item.value.humanInfo.person) {
    arr = item.value.humanInfo.person.upper.style
      ? [t(STYLE[item.value.humanInfo.person.upper.style])]
      : [];
    if (item.value.humanInfo.person.upper.color) {
      item.value.humanInfo.person.upper.color.forEach((e) => {
        arr.push(t(COLOR[e]));
      });
    }
  }
  return arr.join(', ');
});
const kieuQuan = computed(() => {
  let arr = [];
  if (item.value && item.value.humanInfo && item.value.humanInfo.person) {
    arr = item.value.humanInfo.person.lower.style
      ? [t(STYLE[item.value.humanInfo.person.lower.style])]
      : [];
    if (item.value.humanInfo.person.lower.color) {
      item.value.humanInfo.person.lower.color.forEach((e) => {
        arr.push(t(COLOR[e]));
      });
    }
  }
  return arr.join(', ');
});

const color = computed(() => state.listSelect.color);
const vehicleColor = computed(() => {
  const index = color.value.findIndex(
    (data) => data.value === item.value.vehicleInfo.vehicleColor
  );
  if (index !== -1) {
    return color.value[index].name;
  }
  return '';
});

const vehicleBrand = computed(() => {
  if (item.value.vehicleInfo.vehicleBrand !== TYPE.UNKNOWN) {
    return item.value.vehicleInfo.vehicleBrand;
  }
  return '';
});

const mu = computed(
  () => item.value.humanInfo && item.value.humanInfo.person.hat
);
const kinhMat = computed(
  () => item.value.humanInfo && item.value.humanInfo.person.glasses
);

const classGroupTag = (item) => {
  return {
    'bg-ems-main1': item.warningLevelId === WARNING_LEVEL[0].id,
    'bg-ems-boTro2_600': item.warningLevelId === WARNING_LEVEL[1].id,
    'bg-ems-boTro4_800': item.warningLevelId === WARNING_LEVEL[2].id,
    'bg-ems-gray500': item.warningLevelId === WARNING_LEVEL[3].id,
  };
};
const viewGroup = (item) => {
  router.push({
    path: '/io-management/group-management',
    query: { idGroup: item.id },
  });
};
const listNationalities = computed(() => state.nationality.nationalities);
const nationality = computed(() => {
  const index = listNationalities.value.findIndex(
    (data) => data.id === item.value.detail.nationality
  );
  if (index !== -1) {
    return listNationalities.value[index].name;
  }
  return '';
});
const isLargeScreen = ref(false);
onMounted(async () => {
  await getData();
  selectedIndex.value = 0;
  dispatch('tracingObject/getListGroupCamera');
  dispatch('tracingObject/getListCameraByGroups', []);
  dispatch('listSelect/getAllColor');
  dispatch('listSelect/getAllVehicleType');
  dispatch('listSelect/getAllBrand');
  dispatch('nationality/getAllNationalities');
  window.addEventListener('keyup', onKeyUp);
  if (formContainer.value && formContainer.value.offsetHeight > 900) {
    isLargeScreen.value = true;
  }
  new ResizeObserver(resize).observe(formContainer.value);
});

const resize = () => {
  if (formContainer.value && formContainer.value.offsetHeight > 900) {
    isLargeScreen.value = true;
  } else {
    isLargeScreen.value = false;
  }
};

onUnmounted(() => {
  window.removeEventListener('keyup', onKeyUp);
});
const onKeyUp = (e) => {
  if (e.keyCode === 37) {
    onNavigate(NAVIGATION_TYPE.PREV);
  } else if (e.keyCode === 39) {
    onNavigate(NAVIGATION_TYPE.NEXT);
  }
};

const item = computed(() => {
  return listImg.value[selectedIndex.value]
    ? listImg.value[selectedIndex.value]
    : {};
});
const selectedIndex = ref(0);

const formState = reactive({
  groupCameraIds: [],
  cameraIds: [],
  dateFrom: null,
  dateTo: null,
});
const onChangePage = () => {
  getData();
  selectedIndex.value = 0;
};

const selectImg = (idx) => {
  selectedIndex.value = idx;
};

const changeGroupCamera = () => {
  dispatch('tracingObject/getListCameraByGroups', formState.groupCameraIds);
};

const deleteHistory = () => {
  showConfirm.value = true;
};
const resetData = () => {
  formState.groupCameraIds = [];
  formState.cameraIds = [];
  formState.dateFrom = null;
  formState.dateTo = null;
};
const sizeChange = () => {
  if (page.value === 1) {
    getData();
    selectedIndex.value = 0;
  } else {
    page.value = 1;
  }
};
const handleConfirmDelete = async () => {
  showConfirm.value = false;
  let ids = [];
  listImg.value.map((e) => {
    if (e.checked) {
      ids.push(e.id);
    }
  });
  dispatch('ioManagement/deleteHistory', ids.join(',')).then(async () => {
    page.value = 1;
    await getData();
    selectedIndex.value = 0;
  });
};
const getData = async () => {
  isLoading.value = true;
  await dispatch('ioManagement/getIdentificationHistory', {
    ...formState,
    page: page.value - 1,
    size: size.value,
    profileId: route.query.id,
  });
  isLoading.value = false;
};

const onNavigate = (navigationType) => {
  if (navigationType === NAVIGATION_TYPE.PREV) {
    prevImg();
  } else {
    nextImg();
  }
};

const prevImg = async () => {
  if (!(selectedIndex.value === 0 && page.value === 1)) {
    if (selectedIndex.value === 0) {
      page.value = page.value - 1;
      selectedIndex.value = size.value - 1;
      await getData();
    } else selectedIndex.value--;
  }
};
const nextImg = async () => {
  if (
    !(
      selectedIndex.value === listImg.value.length - 1 &&
      page.value === maxPage.value
    )
  ) {
    if (selectedIndex.value === size.value - 1) {
      page.value = page.value + 1;
      selectedIndex.value = 0;
      await getData();
    } else selectedIndex.value++;
  }
};
watch(
  () => formState,
  async (val) => {
    await getData();
    selectedIndex.value = 0;
  },
  {
    deep: true,
  }
);
const onSuggest = () => {
  router.push({
    path: '/io-management/event-verify-management',
    query: { keyword: route.query.id },
  });
};
</script>

<style lang="scss">
.form-history .tag:hover {
  transition: 0.2s;
  transform: scale(1.1);
}
.form-history .disabled-icon {
  cursor: not-allowed;
}
.form-history .shadow-custom {
  box-shadow: 0 4px 84px 0 #000000;
}
.form-history .from-to-date .ant-row {
  margin: 0 !important;
}
.form-history .from-to-date .ant-row .ant-col:first-child {
  padding-left: 0 !important;
}
.form-history .from-to-date .ant-row .ant-col:last-child {
  padding-right: 0 !important;
}
.form-history .from-to-date .ant-row .ant-picker {
  border-radius: 0 !important;
  height: 34px !important;
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-style: none;
}
.form-history .from-to-date .ant-row .ant-form-item-control-input {
  min-height: unset !important;
}
.form-history .ant-select-selector {
  height: 34px !important;
  overflow-x: auto !important;
  padding-right: 0;
  padding-left: 4px;
}
.form-history .ant-select-selector .ant-select-selection-overflow {
  flex-wrap: nowrap;
  height: 34px !important;
}
.form-history .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
  border-radius: 20px;
  border-style: none;
  margin-right: 0.25rem;
}
.form-history .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .ant-select-selection-item-content {
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.form-history .ant-select-selector .ant-select-selection-overflow .ant-select-selection-item .anticon-close {
  --tw-text-opacity: 1;
  color: rgba(217, 217, 217, var(--tw-text-opacity));
}
.form-history .ant-pagination {
  border-top: 1px solid var(--Gray-700---434343---2B2A3A, #2b2a3a);
  background: var(--Gray-1000---171717---101017---0F0F15, #0f0f15);
  margin: 0 !important;
  padding-top: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
  bottom: 10px;
  right: 0px;
  width: 100%;
}
.form-history .ant-pagination .ant-pagination-total-text {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(247, 240, 247, var(--tw-text-opacity));
}
.form-history .ant-pagination .ant-pagination-options {
  flex: 1;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.form-history .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  display: none;
}
.form-history .ant-pagination .ant-pagination-options .ant-select {
  float: right;
  border-color: white;
  --tw-bg-opacity: 1;
  background-color: rgba(111, 111, 111, var(--tw-bg-opacity));
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 20px;
  width: 80px;
}
.form-history .ant-pagination .ant-pagination-options .ant-select .ant-select-arrow {
  color: #f7f0f7;
}
.form-history .ant-pagination .ant-pagination-options .ant-select .ant-select-dropdown {
  background-color: #191919;
}
.form-history .ant-pagination .ant-pagination-options .ant-select .ant-select-dropdown .ant-select-item-option-content {
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.form-history .ant-pagination .ant-pagination-options .ant-select-dropdown-placement-topLeft {
  width: 100px !important;
  left: -10px !important;
}
.form-history .ant-pagination .ant-pagination-item-container {
  width: 100%;
}
.form-history .ant-pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #b4b4b4 !important;
}
.form-history .ant-pagination .ant-pagination-item {
  background-color: #202020;
  color: #b4b4b4;
  font-size: 14px;
  border: none !important;
  margin-bottom: 13px !important;
}
.form-history .ant-pagination .ant-pagination-item a {
  color: #b4b4b4;
}
.form-history .ant-pagination .ant-pagination-item-active {
  background-color: #5b5b9f !important;
  color: #f5f5f5;
  text-shadow: 0px 0px 6px #fff;
  border: none !important;
}
.form-history .ant-pagination .ant-pagination-next {
  border: none !important;
  margin-bottom: 13px !important;
}
.form-history .ant-pagination .ant-pagination-prev {
  border: none !important;
  margin-bottom: 13px !important;
}
.form-history .ant-pagination .ant-pagination-jump-next, .form-history .ant-pagination .ant-pagination-jump-prev {
  border: none !important;
  margin-bottom: 13px !important;
}
.form-history .ant-pagination .ant-pagination-item-link {
  --tw-bg-opacity: 1;
  background-color: rgba(32, 32, 32, var(--tw-bg-opacity));
  border-radius: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  --tw-text-opacity: 1;
  color: rgba(91, 91, 159, var(--tw-text-opacity));
}
.form-history .select-img .ant-checkbox .ant-checkbox-inner {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-radius: 9999px;
}
.form-history .select-img .ant-checkbox:hover .ant-checkbox-inner {
  --tw-border-opacity: 1;
  border-color: rgba(91, 91, 159, var(--tw-border-opacity));
}
.form-history .select-img .ant-checkbox-checked::after {
  border-radius: 9999px;
}
.form-history .select-img .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5b5b9f !important;
  border-color: #5b5b9f !important;
}
.form-history .ant-checkbox-wrapper span {
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.form-history .ant-select-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-top: 2px;
  top: 0px;
  right: 0.25rem;
}
.form-history ::-webkit-scrollbar:horizontal {
  height: 3px;
}
.form-history .ant-image {
  width: 100% !important;
}
.form-history .text-page-cus {
  margin-top: 17px;
  color: var(--Gray-300---C4C4C4---F7F0F7, #f7f0f7);
  font-family: Saira;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.form-history .pagin-history .ant-select-selector {
  overflow-y: hidden;
}
</style>
